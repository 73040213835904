import './SubscriptionView.scss';

import LogoUpCropped from '@assets/Logo/up/LogoUpCropped.png';
import { Warning } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { UnpySubscription } from 'domain/payment/UnpySubscription';
import { UnpySubscriptionStatus } from 'domain/payment/UnpySubscriptionStatus';
import { formatDate } from 'helper/date.helper';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SubscriptionViewProps {
  subscription: UnpySubscription;
  onUpdate: () => Promise<any>;
}

export const SubscriptionView = ({ subscription, onUpdate }: SubscriptionViewProps) => {
  const [open, setOpen] = React.useState(false);
  const [openUndo, setOpenUndo] = React.useState(false);
  const { subscribtionRepository } = useContextDependency();
  const [actionIsLoading, setActionIsLoading] = useState(false);
  const t = useTranslate();

  const handleCancelSubscription = () => {
    setOpen(false);
    setActionIsLoading(true);
    subscribtionRepository.cancel(false).then(() => {
      setTimeout(() => {
        onUpdate().then(() => {
          setActionIsLoading(false);
        });
      }, 500);
    });
  };

  const handleUndoCancelSubscription = () => {
    setOpenUndo(false);
    setActionIsLoading(true);
    subscribtionRepository.undoCancel().then(() => {
      setTimeout(() => {
        onUpdate().then(() => {
          setActionIsLoading(false);
        });
      }, 500);
    });
  };

  const navigate = useNavigate();

  const handleGoSubscribe = () => {
    navigate(routesConfig.up.to());
  };
  const canReactivate =
    subscription.status === UnpySubscriptionStatus.CANCELED ||
    subscription.status === UnpySubscriptionStatus.PAST_DUE;
  const canNewSubscribe =
    subscription.status === UnpySubscriptionStatus.UNPAID ||
    subscription.status === UnpySubscriptionStatus.INCOMPLETE;
  const canUndoCancel =
    subscription.status === UnpySubscriptionStatus.ACTIVE &&
    subscription.cancelAtPeriodEnd;
  const canCancel =
    subscription.status === UnpySubscriptionStatus.ACTIVE &&
    !subscription.cancelAtPeriodEnd;
  return (
    <div className={'subscriptionView'}>
      <div className={'subscriptionView__logoWithTitle'}>
        <div className={'-logo'}>
          <img loading={'lazy'} alt={'logo-unpy-up'} src={LogoUpCropped} />
        </div>
        <h4 className={'-title'}>{t(`product.${subscription.product.code}.libelle`)}</h4>
      </div>
      <div className={'subscriptionView__infos'}>
        <KeyValue
          alignStart
          keyValue={t('subscription.startDateText')}
          value={formatDate(subscription.currentPeriodStart, 'readable(with hour)')}
          accent
        />
        <KeyValue
          alignStart
          keyValue={t('subscription.endDate')}
          value={formatDate(subscription.currentPeriodEnd, 'readable(with hour)')}
          accent
        />
        {subscription.status === UnpySubscriptionStatus.ACTIVE &&
          subscription.cancelAtPeriodEnd && (
            <span className={'-noticeCancelAtPeriodEnd'}>
              <Warning />
              {t('subscription.cancelAction.cancelAtPeriodEndNotice')}
            </span>
          )}
      </div>
      <div className={'subscriptionView__actions'}>
        {canCancel && (
          <LoadingButton
            variant={'contained'}
            color={'secondary'}
            onClick={() => setOpen(true)}
            className={'-btnSubscribe'}
            loading={actionIsLoading}
          >
            {t('subscription.cancelAction.labelBtn')}
          </LoadingButton>
        )}
        {canUndoCancel && (
          <LoadingButton
            variant={'contained'}
            color={'primary'}
            onClick={() => setOpenUndo(true)}
            className={'-btnSubscribe'}
            loading={actionIsLoading}
          >
            {t('subscription.cancelAction.undoCancel')}
          </LoadingButton>
        )}
        {canReactivate && (
          <LoadingButton
            variant={'contained'}
            color={'secondary'}
            onClick={handleGoSubscribe}
            className={'-btnSubscribe'}
            loading={actionIsLoading}
          >
            {t('subscription.reactivateAction')}
          </LoadingButton>
        )}
        {canNewSubscribe && (
          <LoadingButton
            variant={'contained'}
            color={'secondary'}
            onClick={handleGoSubscribe}
            className={'-btnSubscribe'}
            loading={actionIsLoading}
          >
            {t('subscription.reactivateAction')}
          </LoadingButton>
        )}
      </div>
      <ModalConfirm
        handleClose={() => setOpen(false)}
        isOpen={open}
        title={t('subscription.cancelAction.confirmTitleModal')}
        description={t('subscription.cancelAction.confirmText', {
          date: formatDate(subscription.currentPeriodEnd, 'readable(with hour)'),
        })}
        action={handleCancelSubscription}
        labelAccept={t('subscription.cancelAction.confirmBtnText')}
        labelDecline={t('subscription.cancelAction.cancelBtnText')}
      />
      <ModalConfirm
        handleClose={() => setOpenUndo(false)}
        isOpen={openUndo}
        title={t('subscription.undoCancelAction.confirmTitleModal')}
        description={t('subscription.undoCancelAction.confirmText', {
          date: formatDate(subscription.currentPeriodEnd, 'readable(with hour)'),
        })}
        action={handleUndoCancelSubscription}
        labelAccept={t('subscription.undoCancelAction.confirmBtnText')}
        labelDecline={t('subscription.undoCancelAction.cancelBtnText')}
      />
    </div>
  );
};
