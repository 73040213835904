import './StructureCreateForm.scss';

import { Box, TextField } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { setOneConnectedUserStructure } from 'domain/user/store/UserSlice';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { IStructureRepository } from '../../domain/structure/IStructure.repository';
import { RootState } from '../../store';
import { InputWrapper } from '../Components/Input/InputWrapper';
import { sendEventToastMessage } from '../Components/Toast/Toast.helper';
import { useContextDependency } from '../hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from '../hooks/useTranslate';
import { useYupValidationResolver } from '../hooks/useYupValidationResolver';

export type StructureCreateFormData = {
  name: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    name: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    structureRepository: IStructureRepository,
    onSubmitted: (id: number) => void,
    handleClose: () => void,
    dispatch: Dispatch,
    setIsLoaing: (isLoading: boolean) => void,
  ) =>
  (data: StructureCreateFormData) => {
    setIsLoaing(true);
    structureRepository
      .create(data)
      .then((structure) => {
        sendEventToastMessage('Structure créée avec succès', 'success');
        handleClose();
        dispatch(setOneConnectedUserStructure(structure));
        onSubmitted(structure.id);
      })
      .finally(() => setIsLoaing(false));
  };

interface StructureCreateFormProps {
  handleClose: () => void;
  onCreated: (id: number) => void;
}

export const StructureCreateForm: FC<StructureCreateFormProps> = ({
  handleClose,
  onCreated,
}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<StructureCreateFormData>({ resolver });
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const dispatch = useDispatch();
  const { structureRepository } = useContextDependency();
  const [isLoading, setIsLoaing] = React.useState(false);
  const t = useTranslate();
  const onSubmit = useOnSubmit(
    structureRepository,
    onCreated,
    handleClose,
    dispatch,
    setIsLoaing,
  );

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <Box className={'modal-create-structure'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          label={t('structure.form.name')}
          errors={errors}
          isSubmitting={isSubmitting}
          Input={TextField}
          inputProps={{
            placeholder: t('structure.form.namePlaceholder'),
            disabled: isSubmitting,
            ...register('name', { required: true }),
            variant: 'filled',
          }}
          required
        />
        <CoupleButtonValidCancel
          onClickCancel={handleClose}
          loading={isLoading}
          isTypeSubmit
        />
      </form>
    </Box>
  );
};
