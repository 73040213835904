import './ProfilTabs.scss';

import { Grid } from '@mui/material';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { Structure, StructureFilters } from 'domain/structure/Structure';
import { LayoutList } from 'primary/Components/Layout/Lists/LayoutList';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { NoContentBloc } from 'primary/Components/NoContentBloc/NoContentBloc';
import { PreviewCardProfil } from 'primary/Components/Profils/PreviewCardProfil';
import { UserProfilContext } from 'primary/Components/Profils/User/UserProfilCard';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const ProfilTabsStructures = () => {
  const t = useTranslate();
  const { profil } = useOutletContext<UserProfilContext>();
  const { structureRepository } = useContextDependency();

  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    changeNbPerPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<Structure, StructureFilters>({
    filters: {},
    staticFilters: { asMember: true },
    callApi: (filters, page) => structureRepository.search(filters, page, 30),
    keyStoreFilters: 'structures-list-profil',
  });

  return (
    <div className={'-tabsStructures'}>
      <LayoutList
        withoutBottomMarged
        withoutBorder
        className={'-layoutListNotification'}
        resetFilters={() => setFilters({})}
        keyDomainFilter={'structures-list-profil'}
        paginationProps={{
          goToPage: goToPage,
          currentPage: fetchedResource?.currentPage,
          numberOfItem: fetchedResource?.totalElementsCount,
          setRowPerPage: changeNbPerPage,
          numberOfItemPerPage: fetchedResource?.pageSize,
          pagesCount: fetchedResource?.pagesCount || 0,
        }}
        pagination
        listComponent={
          <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
            {fetchedResource?.content?.length != 0 && (
              <ListElements
                loading={loading}
                empty={fetchedResource?.content.length === 0}
              >
                <Grid container>
                  {!loading &&
                    fetchedResource?.totalElementsCount !== 0 &&
                    fetchedResource?.content?.map((structure) => (
                      <PreviewCardProfil
                        className={'-itemLayoutList'}
                        key={profil.id}
                        profil={structure.structureProfil as StructureProfil}
                      />
                    ))}
                </Grid>
              </ListElements>
            )}
            {!loading && !fetchedResource?.content?.length && (
              <NoContentBloc text={t('profil.structures.noStructures')} />
            )}
          </ListElements>
        }
        // @ts-ignore
        filtersTag={activeFilters?.filters}
        noTags
        onDeleteFilter={(filter) => {
          setFilters({ ...activeFilters?.filters, [filter.key]: undefined });
        }}
      />
    </div>
  );
};
