import { setSendMessagePlayerModalClose } from 'domain/modal/store/ModalSlice';
import { SendMessageForm } from 'primary/Components/Profils/User/ActionButton/Forms/SendMessageForm';
import { WrapperModalAction } from 'primary/Components/Profils/User/ActionButton/Modals/WrapperModalAction';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
interface ModalSendMessageProps {
  isOpen: boolean;
  handleClose: () => void;
  userId?: number;
  name: string;
}
export const ModalSendMessage = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const stateModal = useSelector((state: RootState) => state.modal.sendMessagePlayer);
  const handleClose = () => dispatch(setSendMessagePlayerModalClose());
  const { userId, name } = stateModal.props as ModalSendMessageProps;
  if (!userId) return null;
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={stateModal.isOpen}
      header={{
        title: t('modals.sendMessage.title', { name: name }),
        withButtonClose: true,
      }}
      content={
        <WrapperModalAction closeModal={handleClose}>
          <SendMessageForm
            onSubmitted={() => handleClose()}
            userId={userId?.toString()}
            onCancel={() => handleClose()}
          />
        </WrapperModalAction>
      }
    />
  );
};
