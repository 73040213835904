import './ModalAddContent.scss';

import { Box } from '@mui/material';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { FC, ReactNode } from 'react';

import ModalCreateEvent from '../../ModalCreate/ModalCreateEvent';
import { ModalCreateStructure } from '../../ModalCreate/ModalCreateStructure';

interface ModalAddContentProps {
  isOpen: boolean;
  handleClose: () => void;
  modalCreateType: ModalCreateTypes;
}

export type ModalCreateTypes = keyof ModalCreateType;

interface ModalCreateType {
  EVENT: { modalComponent: ReactNode; title: string };
  STRUCTURE: { modalComponent: ReactNode; title: string };
}

export const ModalAddContent: FC<ModalAddContentProps> = ({
  isOpen,
  handleClose,
  modalCreateType,
}) => {
  const t = useTranslate();
  const MODAL_CREATE: ModalCreateType = {
    EVENT: {
      modalComponent: <ModalCreateEvent handleClose={handleClose} />,
      title: t('topbar.create.event.label'),
    },
    STRUCTURE: {
      modalComponent: <ModalCreateStructure handleClose={handleClose} />,
      title: t('topbar.create.structure.label'),
    },
  };

  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      header={{
        title: MODAL_CREATE[modalCreateType]?.title,
        withButtonClose: true,
      }}
      content={
        <Box className={'modal-add-content'}>
          {MODAL_CREATE[modalCreateType]?.modalComponent}
        </Box>
      }
    />
  );
};
