import {
  selectBackgroundImageById,
  setBackgroundImageById,
} from 'domain/backgroundImage/store/BackgroundImageSlice';
import { Neon } from 'domain/neon/Neon';
import { selectNeonById, setNeonById } from 'domain/neon/store/NeonSlice';
import { UserProfil } from 'domain/profil/UserProfil';
import { UserProfilView } from 'domain/user/UserProfilView';
import { formatDate } from 'helper/date.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomainToContext } from 'primary/hooks/useRetrieveFromDomain';
import { UseTranslateReturn } from 'primary/hooks/useTranslate';
import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import { RootState } from 'store';

export const useGenerateDraftFromAppearence = (
  appearence: ProfilAppearenceFormData,
  profil?: UserProfil,
): UserProfil | undefined => {
  if (!profil) return undefined;

  const { backgroundImageRepostory, neonRepository } = useContextDependency();

  const [, , background] = useRetrieveFromDomainToContext(
    () =>
      backgroundImageRepostory.getBackgroundImageById(
        appearence.backgroundImageId?.id as number,
      ),
    undefined,
    !!appearence.backgroundImageId,
    setBackgroundImageById,
    (state: RootState) =>
      selectBackgroundImageById(state, appearence.backgroundImageId?.id as number),
  );

  const [, , neon] = useRetrieveFromDomainToContext(
    () => neonRepository.getNeonById(appearence.neon?.id as number),
    undefined,
    !!appearence.neon,
    setNeonById,
    (state: RootState) => selectNeonById(state, appearence.neon?.id as number),
  );

  return {
    ...profil,
    photo: appearence.fileNameUploaded || profil.photo,
    backgroundImage: background,
    neon: neon as Neon,
  };
};

export const getInitialValuesProfilUserDetails = (
  t: UseTranslateReturn,
  profil?: UserProfil,
) => {
  if (!profil)
    return {
      idPlayer: '',
      description: '',
      talkLangs: [],
      platforms: [],
      gamingProfil: '',
    };
  return {
    idPlayer: profil.idPlayer,
    description: profil.description,
    talkLangs: profil.talkLangs?.map((lang) => lang.id.toString()),
    platforms: profil.platforms?.map((platform) => platform.id.toString()),
    gamingProfil: profil.gamingProfil,
  };
};

export const getInitialValuesUserProfilNetwork = (profil?: UserProfil) => {
  if (!profil)
    return {
      discord: '',
      twitch: '',
      youtube: '',
      twitter: '',
      instagram: '',
      facebook: '',
      ping: false,
      video: '',
      privacies: {},
    };
  else {
    return {
      discord: profil.discord,
      twitch: profil.twitch,
      youtube: profil.youtube,
      twitter: profil.twitter,
      instagram: profil.instagram,
      facebook: profil.facebook,
      video: '',
      ping: false,
      privacies: {
        ...profil.privacies?.reduce((acc, privacy) => {
          return {
            ...acc,
            [privacy.code]: {
              profilId: privacy.profil.id,
              code: privacy.code,
              isChecked: privacy.isChecked,
            },
          };
        }, {}),
      },
    };
  }
};

export const getInitialValuesUserProfilPersonnalData = (profil?: UserProfil) => {
  if (!profil)
    return {
      birthdate: '',
      country: '',
    };
  else {
    return {
      birthdate: formatDate(profil.birthdate, 'back'),
      country: profil?.country?.id?.toString() || '',
    };
  }
};

export const toUserViewFromUserProfil = (profil: UserProfil) => {
  return new UserProfilView(
    profil.userId as number,
    profil.id,
    profil.idPlayer,
    profil.photo as string,
    !!profil.defaultPhoto,
    undefined,
    profil.backgroundImage,
    profil.neon,
  );
};
