import './FilterTalkLang.scss';

import { Lang } from 'domain/lang/Lang';
import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectTag } from 'primary/Components/Input/InputSelectTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import React from 'react';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';
import { useTranslate } from '../../hooks/useTranslate';

type FilterTalkLangProps = {
  onSelectLang: (values: string[]) => void;
  selectedLangs?: string[];
};

export const FilterTalkLang = ({ onSelectLang, selectedLangs }: FilterTalkLangProps) => {
  const t = useTranslate();
  const { langRepository } = useContextDependency();
  const [talkLangs] = useFetch(() => langRepository.getAll(), undefined);
  if (!talkLangs) return null;
  return (
    <div>
      <InputSelectTag
        placeholder={t('mercato.filters.placeholders.langs')}
        options={insertEmptyOpt(
          talkLangs?.map((lang: Lang) => ({
            label: lang.libelle,
            value: lang.code,
          })) || [],
        )}
        onChange={(event: ChangeHandlerTypeMeta) => {
          //ts-ignore
          onSelectLang(event.target.value as string[]);
        }}
        value={selectedLangs}
        menuPortalTarget={document.body}
      />
    </div>
  );
};
