import { setInviteEventModalClose } from 'domain/modal/store/ModalSlice';
import { InviteEventForm } from 'primary/Components/Profils/User/ActionButton/Forms/InviteEventForm';
import { WrapperModalAction } from 'primary/Components/Profils/User/ActionButton/Modals/WrapperModalAction';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export interface ModalInviteEventProps {
  userId?: number;
  name: string;
}
export const ModalInviteEvent = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const stateModal = useSelector((state: RootState) => state.modal.inviteEvent);
  const handleClose = () => dispatch(setInviteEventModalClose());
  const { userId, name } = stateModal.props as ModalInviteEventProps;
  if (!userId) return null;
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={stateModal.isOpen}
      header={{
        title: t('modals.inviteEvent.title', { name: name }),
        withButtonClose: true,
      }}
      content={
        <WrapperModalAction closeModal={handleClose}>
          <InviteEventForm
            onSubmitted={() => handleClose()}
            userId={userId?.toString()}
            onCancel={() => handleClose()}
          />
        </WrapperModalAction>
      }
    />
  );
};
