import './Home.scss';

import { HomeFollowSection } from 'primary/Home/Sections/HomeFollowSection';
import { HomeUpSection } from 'primary/Home/Sections/HomeUpSection';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC } from 'react';

import { HomeSlidersSection } from './Sections/HomeSlidersSection';

export type HomeSectionProps = {
  className?: string;
};

export const Home: FC = () => {
  const t = useTranslate();
  return (
    <div className={'home'}>
      <HomeSlidersSection key={'home-section-1'} className={'home__section-1'} />
      <HomeUpSection key={'home-section-4'} className={'home__section-4'} />
      <HomeFollowSection key={'home-section-5'} className={'home__section-5'} />
    </div>
  );
};
