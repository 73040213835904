import './MyEventsPage.scss';

import { Cancel } from '@mui/icons-material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { KeyDomainFilters } from 'domain/filters/FiltersSlice';
import { InfiniteScroll } from 'primary/Components/InfiniteScroll/InfiniteScroll';
import { LeftRight } from 'primary/Components/Layout/LeftRight/LeftRight';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { ModalAddContent } from 'primary/Components/User/ModalAddContent';
import { routesConfig } from 'primary/Configs/Routes.config';
import { EventViewPage } from 'primary/events/item/EventViewPage';
import { SimpleEventListElement } from 'primary/events/SimpleEventListElement';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UnpyTabs } from '../../Components/Tabs/UnpyTabs';

interface EventPreviewListProps {
  handled?: boolean;
  setEventSelected: (event: UnpyEventView | null) => void;
  selectedEvent: UnpyEventView | null;
  domainFilter: KeyDomainFilters;
}

export const NoContentPreviewProfil = () => {
  return (
    <div className={'-noContentPreviewProfil'}>
      <span>Sélectionnez une structure pour afficher son profil</span>
    </div>
  );
};
export const EventPreviewList = ({
  handled,
  setEventSelected,
  selectedEvent,
  domainFilter,
}: EventPreviewListProps) => {
  const { eventRepository } = useContextDependency();

  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<UnpyEventView, UnpyEventFilters>({
    filters: {},
    staticFilters: {},
    callApi: (filters, page) =>
      handled
        ? eventRepository.searchOwn(filters, page, 30)
        : eventRepository.searchRegistered(filters, page, 30),
    keepPageResult: true,
    keyStoreFilters: domainFilter,
  });
  const t = useTranslate();

  return (
    <div className={classNames('myEventsPage__eventsList')}>
      {loading && <LoadingScreen loading />}
      {!loading && !allContents?.length && (
        <div className={'-noContent'}>
          <Cancel />
          <span>{t(`myEvents.noContent.${handled ? 'handled' : 'registered'}`)}</span>
        </div>
      )}
      <InfiniteScroll onScrollBottom={() => nextPage()} isLoading={loading}>
        {(scrollToTop) =>
          !loading &&
          allContents?.length != 0 &&
          allContents?.map((event) => (
            <SimpleEventListElement
              key={event.id}
              event={event}
              isSelected={event.id === selectedEvent?.id}
              onClick={(event) =>
                setEventSelected(event.id === selectedEvent?.id ? null : event)
              }
            />
          ))
        }
      </InfiniteScroll>
    </div>
  );
};

export const MyEventsPage: FC = () => {
  const [selectedEvent, setSelectedEvent] = useState<UnpyEventView | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const { authRepository } = useContextDependency();
  const [isModalCreateEventOpen, setIsModalCreateEventOpen] = useState(false);
  const t = useTranslate();
  const navigate = useNavigate();

  const tabsConf = [
    {
      label: t(`myEvents.tabs.handled`),
      component: (
        <EventPreviewList
          setEventSelected={setSelectedEvent}
          selectedEvent={selectedEvent}
          key={'myEventPage-created'}
          domainFilter={'myStructuresPage-created'}
          handled
        />
      ),
      to: routesConfig.myEvents.handled.to,
    },
    {
      label: t(`myEvents.tabs.registered`),
      component: (
        <EventPreviewList
          setEventSelected={setSelectedEvent}
          selectedEvent={selectedEvent}
          key={'myEventPage-handled'}
          domainFilter={'myStructuresPage-handled'}
        />
      ),
      to: routesConfig.myEvents.registered.to,
    },
  ];

  if (!authRepository.currentUser) return null;

  return (
    <div className={'myEventsPage'}>
      <LeftRight
        sizeLeft={'big'}
        close={() => setSelectedEvent(null)}
        transparency={'opaq'}
        isOpen={!!selectedEvent}
        scrollInside
        left={
          <div className={'myStructuresPage__listStructures'}>
            <div className={'myStructuresPage__listStructures-header'}>
              <h3>{t('myEvents.pageTitle')}</h3>
              <Button
                variant={'contained'}
                onClick={() => setIsModalCreateEventOpen(true)}
                color={'primary'}
                type={'submit'}
                className={'-actionButton'}
              >
                {t('myEvents.createEvent')}
              </Button>
              <Button
                className={'-actionButton'}
                variant={'contained'}
                onClick={() => navigate(routesConfig.events.to())}
                color={'primary'}
                type={'submit'}
              >
                {t('myEvents.searchEvents')}
              </Button>
            </div>
            <div className={'myStructuresPage__listStructures-list'}>
              <UnpyTabs
                tabsConf={tabsConf}
                center
                fullHeight
                asFlex
                onChange={(activeTab) => {
                  setSelectedEvent(null);
                  setActiveTab(activeTab);
                }}
              />
            </div>
          </div>
        }
        right={
          <>
            {selectedEvent && (
              <div className={'-containerEvent --scrollBarContent'}>
                <EventViewPage
                  eventForPreview={selectedEvent}
                  isHandled={activeTab === 0}
                />
              </div>
            )}
          </>
        }
      />
      <ModalAddContent
        isOpen={isModalCreateEventOpen}
        handleClose={() => setIsModalCreateEventOpen(false)}
        modalCreateType={'EVENT'}
      />
    </div>
  );
};
