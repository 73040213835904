import './InputRadios.scss';

import { Check } from '@mui/icons-material';
import { Button, Chip, FormControl, FormLabel } from '@mui/material';
import classNames from 'classnames';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { InputActionMeta } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { InputProps, Option, ValueType } from './InputWrapper';

type InputRadiosProps<T extends ValueType = string, M = object> = {
  AsComponent?: (props: RadioAsComonentProps) => ReactNode;
  required?: boolean;
  // @ts-ignore
  options?: Option<string | ReactNode, M>[];
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  filterOption?: (opt: FilterOptionOption<Option<string, M>>, input: string) => boolean;
  isOptionDisabled?: (option: Option<string, M>) => boolean;
  orientation?: 'vertical' | 'horizontal';
  asTag?: boolean;
  noWrap?: boolean;
  fullWidth?: boolean;
} & InputProps<T>;

export type RadioAsComonentProps = {
  selected?: boolean;
  opt?: Option;
  onChange: (e: { target: { value: string } }) => void;
};

export const InputRadios = forwardRef(function InputRadiosForwarded(
  {
    options,
    name,
    onChange,
    value,
    label,
    orientation,
    asTag,
    fullWidth,
    AsComponent,
    noWrap,
  }: InputRadiosProps,
  ref: Ref<HTMLElement>,
) {
  return (
    <div className={'input-radio-container'}>
      <FormLabel id={name}>{label}</FormLabel>
      <FormControl className={'-control'}>
        <div
          className={classNames('input-radios', {
            '-vertical': orientation === 'vertical',
            '-fullWidth': fullWidth,
            '-noWrap': noWrap,
          })}
        >
          {options?.map((opt) => (
            <div className={'-radioButtonItem'}>
              {!AsComponent && !asTag && (
                <Button
                  color={'primary'}
                  style={{ justifySelf: 'flex-end' }}
                  variant={opt.value === value ? 'contained' : 'outlined'}
                  onClick={() => {
                    onChange?.({ target: { value: opt.value } });
                  }}
                >
                  <span className={classNames({ '-selected': opt.value === value })}>
                    {opt.label}
                  </span>
                </Button>
              )}
              {!AsComponent && asTag && (
                <Chip
                  onClick={() => {
                    onChange?.({ target: { value: opt.value } });
                  }}
                  className={classNames('-tagGame')}
                  label={
                    <>
                      <span>{opt.label}</span>
                      {opt.value === value && <Check />}
                    </>
                  }
                />
              )}
              {AsComponent &&
                AsComponent({
                  selected: opt.value === value,
                  // @ts-ignore
                  opt: opt,
                  onChange: () => onChange?.({ target: { value: opt.value } }),
                })}
              <input hidden name={name} type={'radio'} aria-label={name} />
            </div>
          ))}
        </div>
      </FormControl>
    </div>
  );
});
