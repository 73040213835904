import './InvalidAccessPage.scss';

import { CancelOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export const InvalidAccessPage = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  return (
    <div className={'invalidAccessPage'}>
      <CancelOutlined />
      <h3>{t('general.invalidAccessPage')}</h3>
      <Button
        variant={'contained'}
        onClick={() => navigate(routesConfig.home.to())}
        color={'primary'}
      >
        {t('general.linkToHome')}
      </Button>
    </div>
  );
};
