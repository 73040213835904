import './PreviewEventActions.scss';

import { Group, Settings, Visibility } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useGetPlayerOfEvent } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { setTeamModalOpen } from 'domain/modal/store/ModalSlice';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { routesConfig } from '../../Configs/Routes.config';
import { useTranslate } from '../../hooks/useTranslate';

interface PreviewEventActionsProps {
  event: UnpyEvent | UnpyEventView;
  isHandled?: boolean;
}

export const PreviewEventActions = ({ event, isHandled }: PreviewEventActionsProps) => {
  const navigate = useNavigate();
  const t = useTranslate();
  const { authRepository } = useContextDependency();
  const { eventParticipant: teamRegistered, retry: retryPlayer } = useGetPlayerOfEvent(
    event.id,
    authRepository?.currentUser?.userProfil?.id,
  );
  const dispatch = useDispatch();
  const openModalTeam = () => {
    dispatch(
      setTeamModalOpen({
        props: {
          event: event as UnpyEventView,
          teamRegistered: teamRegistered,
          afterChanges: retryPlayer as () => void,
        },
      }),
    );
  };

  return (
    <div className={'previewEventActions'}>
      <Button
        variant={'contained'}
        onClick={() =>
          navigate(routesConfig.eventViewDetails.eventViewInfos.to(event.id))
        }
        color={'primary'}
        className={'-registerBtn'}
      >
        <Visibility />
        {t('goToPageEvent')}
      </Button>
      {isHandled && (
        <Button
          variant={'contained'}
          onClick={() => navigate(routesConfig.parametersEvent.to(event.id))}
          color={'primary'}
          className={'-registerBtn'}
        >
          <Settings />
          {t('goToParamEvent')}
        </Button>
      )}
      {!isHandled && (
        <Button
          variant={'contained'}
          onClick={openModalTeam}
          color={'secondary'}
          className={'-registerBtn'}
        >
          <Group />
          {t('event.eventViewPage.seeTeam')}
        </Button>
      )}
    </div>
  );
};
