import { setSendMessageStructureModalClose } from 'domain/modal/store/ModalSlice';
import { SendMessageStructureForm } from 'primary/Components/Profils/User/ActionButton/Forms/SendMessageStructureForm';
import { WrapperModalAction } from 'primary/Components/Profils/User/ActionButton/Modals/WrapperModalAction';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
interface ModalSendMessageProps {
  isOpen: boolean;
  handleClose: () => void;
  structureId?: number;
  name: string;
}
export const ModalSendMessageToStructure = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const stateModal = useSelector((state: RootState) => state.modal.sendMessageStructure);
  const handleClose = () => dispatch(setSendMessageStructureModalClose());
  const { structureId, name } = stateModal.props as ModalSendMessageProps;
  if (!structureId) return null;
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={stateModal.isOpen}
      header={{
        title: t('modals.sendMessageToStrucure.title', { name: name }),
        withButtonClose: true,
      }}
      content={
        <WrapperModalAction closeModal={handleClose}>
          <SendMessageStructureForm
            onSubmitted={() => handleClose()}
            structureId={structureId?.toString()}
            onCancel={() => handleClose()}
          />
        </WrapperModalAction>
      }
    />
  );
};
