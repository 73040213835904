import { ClassementParticipant } from 'domain/classement/ClassementParticipant';

export class PodiumProfil {
  constructor(
    readonly firstPlace: ClassementParticipant[],
    readonly secondPlace: ClassementParticipant[],
    readonly thirdPlace: ClassementParticipant[],
    readonly noPodium: ClassementParticipant[],
  ) {}
}
