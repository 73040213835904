import {
  KeyDomainFilters,
  selectFiltersByDomain,
  setFiltersByKey,
} from 'domain/filters/FiltersSlice';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { UserProfilFilters } from 'domain/profil/UserProfil';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export const useSelectionGameListPage = (keyDomainFilters: KeyDomainFilters) => {
  const dispatch = useDispatch();
  const filtersDomain: UserProfilFilters = useSelector((rootState: RootState) =>
    selectFiltersByDomain(rootState, keyDomainFilters),
  ) as UserProfilFilters;
  const selectedGamesCode = (filtersDomain?.games || []) as GAME_ACTIVE_LIST[];


  const handleSelection = useCallback(
    (value: string, type: 'ADD' | 'REMOVE') => {
      if (type === 'ADD') {
        dispatch(
          setFiltersByKey({
            key: keyDomainFilters,
            filters: {
              ...filtersDomain,
              games: [...selectedGamesCode, value],
            },
          }),
        );
      }
      if (type === 'REMOVE') {
        dispatch(
          setFiltersByKey({
            key: keyDomainFilters,
            filters: {
              ...filtersDomain,
              games: selectedGamesCode.filter((game) => game !== value),
            },
          }),
        );
      }
    },
    [filtersDomain],
  );

  return {
    selectedGamesCode,
    handleSelection,
  };
};
