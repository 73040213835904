import './TalkLangsRow.scss';

import { Chip } from '@mui/material';
import classNames from 'classnames';
import { Country } from 'domain/country/Country';
import { LangEnum } from 'domain/enums/Lang.enum';
import { IconFlag } from 'primary/Components/Icon/IconFlag';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

interface TalkLangsRowProps {
  langs?: LangEnum[];
  country?: Country;
  embedded?: boolean;
  withoutCountry?: boolean;
}

export const getCountryLibelle = (libelle: string, embed?: boolean) => {
  if (!libelle || libelle.length < 3) return libelle;
  if (embed) {
    const firstChar = libelle.substring(0, 1);
    const lastChar = libelle.substring(libelle.length - 1);
    return `${firstChar}...${lastChar}`;
  } else {
    return libelle;
  }
};
export const TalkLangsRow = ({
  langs,
  embedded,
  country,
  withoutCountry,
}: TalkLangsRowProps) => {
  const t = useTranslate();
  return (
    <div className={classNames('talkLangsRow', { '-embedded': embedded })}>
      {!withoutCountry && !!country && (
        <div className={'-lang'} title={country.libelle}>
          <IconFlag codeISO={country.code} />
        </div>
      )}
      {langs?.map((code: LangEnum) => (
        <div className={'-lang'} key={code} title={t(`enum.lang.${code}`)}>
          <Chip
            size={embedded ? 'small' : 'medium'}
            label={embedded ? code : t(`enum.lang.${code}`)}
          />
        </div>
      ))}
      {langs?.length === 0 && !embedded && (
        <span className={'-lang'}>{t('myProfil.noTalkLank')}</span>
      )}
    </div>
  );
};
