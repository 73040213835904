import './EventList.scss';

import { TextField } from '@mui/material';
import { IEventRepository } from 'domain/event/Event.repository';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { debounce } from 'lodash';
import { FlexedList } from 'primary/Components/FlexedList/FlexedList';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { ListGameSelectable } from 'primary/Components/UserStatsGameForms/ListGameSelectable';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

import { EventCard } from '../../Components/Event/EventCard';
import { EventsFilters } from '../../Components/Filters/Events/EventsFilters';
import { LayoutList, LayoutListProps } from '../../Components/Layout/Lists/LayoutList';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetchWithFilterPage } from '../../hooks/useFetchWithFilterPage';

export type TypeSearchEvent = 'PUBLIC' | 'OWN' | 'REGISTERED';
interface EventListProps {
  title?: string;
  gamesSelected?: GAME_ACTIVE_LIST[];
  typeSearch: TypeSearchEvent;
  listProps?: Partial<LayoutListProps<any>>;
}

const methodByTypeSearch = {
  ['PUBLIC']: 'searchPublic',
  ['OWN']: 'searchOwn',
  ['REGISTERED']: 'searchRegistered',
};

export const getCallApi =
  (eventRepository: IEventRepository, typeSearch: TypeSearchEvent) =>
  (filters: UnpyEventFilters, page: number) => {
    if (typeSearch === 'PUBLIC') {
      return eventRepository.searchPublic(filters, page, 32);
    }
    if (typeSearch === 'OWN') {
      return eventRepository.searchOwn(filters, page, 32);
    }
    return eventRepository.searchRegistered(filters, page, 32);
  };

export const EventList = ({
  gamesSelected = [],
  typeSearch,
  title,
  listProps,
}: EventListProps) => {
  const { eventRepository } = useContextDependency();
  const defaultFilters: UnpyEventFilters = {
    games: gamesSelected?.length > 0 ? gamesSelected : undefined,
    startDateTime: undefined,
  };
  // @ts-ignore
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    changeNbPerPage,
    setFilters,
  } = useFetchWithFilterPage<UnpyEventView, UnpyEventFilters>({
    keyStoreFilters: 'event',
    filters: defaultFilters,
    callApi: getCallApi(eventRepository, typeSearch),
  });
  const setFiltersDebonced = debounce((name: string) => {
    setFilters({ ...activeFilters, name: name });
  }, 500);
  const t = useTranslate();
  return (
    <LayoutList
      onDeleteFilter={(filter) => {
        //ts-ignore
        const keyGame = Object.keys(GAME_ACTIVE_LIST).find(
          (g) =>
            //@ts-ignore
            t(`filtersValues.${GAME_ACTIVE_LIST[g] as GAME_ACTIVE_LIST}`) ===
            filter.value,
        );
        setFilters({
          ['games']: activeFilters.filters.games?.filter((g) => g !== keyGame),
        });
      }}
      title={title ?? t('event.eventsListPage.titleEventList')}
      tablePagination
      filtersBarComponent={
        <EventsFilters
          typeSearch={typeSearch}
          setFilters={setFilters}
          resetFilters={() => setFilters({})}
        />
      }
      keyDomainFilter={'event'}
      paginationProps={{
        goToPage: goToPage,
        currentPage: fetchedResource?.currentPage,
        numberOfItem: fetchedResource?.totalElementsCount,
        setRowPerPage: changeNbPerPage,
        numberOfItemPerPage: fetchedResource?.pageSize,
        pagesCount: fetchedResource?.pagesCount || 0,
      }}
      pagination
      resetFilters={() => setFilters({})}
      gamesComponent={
        <ListGameSelectable
          inline
          size={'small'}
          flex
          games={Object.values(GAME_ACTIVE_LIST)}
          action={(gamecode) => {
            if (activeFilters?.filters?.games?.includes(gamecode)) {
              setFilters({
                ...activeFilters,
                games: activeFilters?.filters?.games?.filter((g) => g !== gamecode),
              });
            } else {
              setFilters({
                ...activeFilters,
                games: [...(activeFilters?.filters?.games ?? []), gamecode],
              });
            }
          }}
          //@ts-ignore
          gamesSelected={activeFilters?.filters.games ?? []}
        />
      }
      searchTextComponent={
        <TextField
          variant={'standard'}
          style={{ width: '100%', marginBottom: '10px', padding: '0 4px' }}
          placeholder={"Recherche par nom d'événement"}
          onChange={(event) => {
            setFiltersDebonced(event.target.value);
            return event;
          }}
        />
      }
      listComponent={
        <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
          <FlexedList>
            {fetchedResource?.content.map((event) => (
              <div className={'-item'}>
                <EventCard key={event.id} event={event} />
              </div>
            ))}
          </FlexedList>
        </ListElements>
      }
      {...(listProps ?? {})}
    />
  );
};
