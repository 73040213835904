import './ActionButton.scss';

import { PersonAdd } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { setModalJoinStructureOpen } from 'domain/modal/store/ModalSlice';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { isPrivacyRestricted } from 'domain/profil/Profil.func';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { ModalJoinStructureProps } from 'primary/Components/Profils/User/ActionButton/Modals/ModalJoinStructure';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRedirectUrlForAction } from 'primary/hooks/useOpenModalActionFromLogin';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch } from 'react-redux';

interface ActionButtonJoinStructureProps {
  profil: StructureProfil;
  withIcon?: boolean;
}

export const ActionButtonJoinStructure = ({
  profil,
  withIcon,
}: ActionButtonJoinStructureProps) => {
  const t = useTranslate();
  const isRestricted = isPrivacyRestricted(profil, PRIVACY_CODE.JOIN_STRUCTURE);
  const { authRepository } = useContextDependency();
  const dispatch = useDispatch();
  const urlRedirect = useRedirectUrlForAction(
    'joinStructure',
    profil?.id?.toString(),
    profil?.name as string,
  );

  const openModal = () => {
    if (authRepository?.currentUser) {
      dispatch(
        setModalJoinStructureOpen({
          props: {
            structureProfilId: profil.id,
            name: profil.name as string,
          } as ModalJoinStructureProps,
        }),
      );
    } else {
      if (urlRedirect) {
        authRepository?.login(urlRedirect.toString());
      }
    }
  };

  return (
    <>
      <LoadingButton
        color={'inherit'}
        variant={'text'}
        className={'-actionButton'}
        onClick={stopPropagationBeforeAction(openModal)}
        disabled={isRestricted}
      >
        {withIcon && <PersonAdd />}
        {t('profil.userProfilAction.joinStructure')}
      </LoadingButton>
    </>
  );
};
