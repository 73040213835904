import './ProfilTabs.scss';

import {
  StructureMember,
  StructureMemberFilters,
} from 'domain/structureMember/StructureMember';
import { StructureMemberState } from 'domain/structureMember/StructureMemberState';
import { FlexedList } from 'primary/Components/FlexedList/FlexedList';
import { LayoutList } from 'primary/Components/Layout/Lists/LayoutList';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { StructureProfilContext } from 'primary/Components/Profils/structure/StructureProfilCard';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import { PreviewCardProfilWithRole } from 'primary/Parameters/structure/Admin/PreviewCardProfilWithRole';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const ProfilTabsMembers = () => {
  const t = useTranslate();
  const { profil } = useOutletContext<StructureProfilContext>();
  const { structureMemberRepository, roleStructureRepository } = useContextDependency();

  const staticFilters = {
    idStructure: profil?.structure?.id?.toString(),
    state: StructureMemberState.ACCEPTED,
  };
  const {
    loading,
    fetchedResource,
    setFilters,
    activeFilters,
    goToPage,
    changeNbPerPage,
  } = useFetchWithFilterPage<StructureMember, StructureMemberFilters>({
    keyStoreFilters: 'structureMember',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page, nbPerPage) =>
      structureMemberRepository.search(filters, page, nbPerPage),
  });
  const valueOrNoContent = (value: string | number | undefined) =>
    value?.toString() || t('general.keyValueNoValue');
  return (
    <div className={'-tabsMembers'}>
      <LayoutList
        withoutBottomMarged
        withoutBorder
        className={'-layoutListNotification'}
        resetFilters={() => setFilters({})}
        keyDomainFilter={'structureMember'}
        paginationProps={{
          goToPage: goToPage,
          currentPage: fetchedResource?.currentPage,
          numberOfItem: fetchedResource?.totalElementsCount,
          setRowPerPage: changeNbPerPage,
          numberOfItemPerPage: fetchedResource?.pageSize,
          pagesCount: fetchedResource?.pagesCount || 0,
        }}
        pagination
        listComponent={
          <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
            <FlexedList>
              {!loading &&
                fetchedResource?.totalElementsCount !== 0 &&
                fetchedResource?.content.map((member) => (
                  <div key={member.user.id} className={'-itemMemberInProfil'}>
                    <PreviewCardProfilWithRole
                      readonly={true}
                      key={member.user.id}
                      profil={member.user.userProfil}
                      structure={profil.structure}
                      userId={member.user.id}
                      role={member.role}
                      isTemp={member.state !== StructureMemberState.ACCEPTED}
                    />
                  </div>
                ))}
            </FlexedList>
          </ListElements>
        }
        // @ts-ignore
        filtersTag={activeFilters?.filters}
        noTags
        onDeleteFilter={(filter) => {
          setFilters({ ...activeFilters?.filters, [filter.key]: undefined });
        }}
      />
    </div>
  );
};
