import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import React from 'react';
import { UseControllerReturn } from 'react-hook-form/dist/types';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';
import { useTranslate } from '../../hooks/useTranslate';
import { InputWrapper } from '../Input/InputWrapper';

interface EventSelectorProps {
  userId: number;
  errors: any;
  control: UseControllerReturn<any, any>;
  participationAsEventName?: string;
}

export const EventSelector = ({ userId, errors, control }: EventSelectorProps) => {
  const { eventRepository } = useContextDependency();
  const [events, , fetching] = useFetch(
    () => eventRepository.getEventRegisterAvailableConnectedUserOwner(),
    undefined,
    !!userId,
  );

  const t = useTranslate();
  return (
    <InputWrapper
      label={t('ping.form.eventChoiceLabel')}
      errors={errors}
      Input={InputSelectMui}
      isSubmitting={false}
      required
      inputProps={{
        ...control.field,
        menuPortalTarget: document.getElementById('root'),
        isLoading: fetching,
        onLoadingMessage: t('ping.form.eventsLoadingPlaceholder'),
        noContentMessage: t('ping.form.noEvents'),
        options:
          events?.map((event) => {
            const canInvite = true;
            return {
              label: `${event.name} ${
                canInvite ? '' : `(${t('ping.form.noPermission')})`
              }`,
              value: event.id.toString(),
              disabled: !canInvite,
            };
          }) ?? [],
        //onChangeText: _changeHandlerType(setValue),
      }}
    />
  );
};
