import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalInviteEventProps } from 'primary/Components/Profils/User/ActionButton/Modals/ModalInviteEvent';
import { ModalInviteStructureProps } from 'primary/Components/Profils/User/ActionButton/Modals/ModalInviteStructure';
import { ModalJoinStructureProps } from 'primary/Components/Profils/User/ActionButton/Modals/ModalJoinStructure';
import { ModalTeamEventProps } from 'primary/events/modals/ModalTeamEvent';

export type MODAL_TYPE = 'team' | 'link';

type InitialModalSliceState = {
  team: {
    isOpen: boolean;
    props?: ModalTeamEventProps;
  };
  inviteStructure: {
    isOpen: boolean;
    props?: ModalInviteStructureProps;
  };
  joinStructure: {
    isOpen: boolean;
    props?: ModalJoinStructureProps;
  };
  addFriend: {
    isOpen: boolean;
    props?: ModalInviteStructureProps;
  };
  sendMessageStructure: {
    isOpen: boolean;
    props?: ModalInviteStructureProps;
  };
  sendMessagePlayer: {
    isOpen: boolean;
    props?: ModalInviteStructureProps;
  };
  inviteEvent: {
    isOpen: boolean;
    props?: ModalInviteEventProps;
  };
  modalConfirm: {
    isOpen: boolean;
    props?: any;
  };
  modalLeaveStructure: {
    isOpen: boolean;
    props?: ModalJoinStructureProps;
  };
};

export const modalSlice = createSlice({
  name: 'user',
  initialState: {
    team: {
      isOpen: false,
      props: {},
    },
    inviteStructure: {
      isOpen: false,
      props: {},
    },
    sendMessageStructure: {
      isOpen: false,
      props: {},
    },
    sendMessagePlayer: {
      isOpen: false,
      props: {},
    },
    addFriend: {
      isOpen: false,
      props: {},
    },
    inviteEvent: {
      isOpen: false,
      props: {},
    },
    modalConfirm: {
      isOpen: false,
      props: {},
    },
    joinStructure: {
      isOpen: false,
      props: {},
    },
    modalLeaveStructure: {
      isOpen: false,
      props: {},
    },
  } as InitialModalSliceState,
  reducers: {
    setTeamModalOpen: (state, action: PayloadAction<{ props: ModalTeamEventProps }>) => {
      state.team = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setTeamModalClose: (state) => {
      state.team = {
        isOpen: false,
        props: {} as ModalTeamEventProps,
      };
    },
    setInviteStructureModalOpen: (
      state,
      action: PayloadAction<{ props: ModalInviteStructureProps }>,
    ) => {
      state.inviteStructure = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setInviteStructureModalClose: (state) => {
      state.inviteStructure = {
        isOpen: false,
        props: {} as ModalInviteStructureProps,
      };
    },
    setInviteEventModalOpen: (
      state,
      action: PayloadAction<{ props: ModalInviteEventProps }>,
    ) => {
      state.inviteEvent = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setInviteEventModalClose: (state) => {
      state.inviteEvent = {
        isOpen: false,
        props: {} as ModalInviteEventProps,
      };
    },
    setSendMessageStructureModalOpen: (
      state,
      action: PayloadAction<{ props: ModalInviteStructureProps }>,
    ) => {
      state.sendMessageStructure = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setSendMessageStructureModalClose: (state) => {
      state.sendMessageStructure = {
        isOpen: false,
        props: {} as ModalInviteStructureProps,
      };
    },
    setSendMessagePlayerModalOpen: (
      state,
      action: PayloadAction<{ props: ModalInviteStructureProps }>,
    ) => {
      state.sendMessagePlayer = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setSendMessagePlayerModalClose: (state) => {
      state.sendMessagePlayer = {
        isOpen: false,
        props: {} as ModalInviteStructureProps,
      };
    },
    setAddFriendModalOpen: (
      state,
      action: PayloadAction<{ props: ModalInviteStructureProps }>,
    ) => {
      state.addFriend = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setAddFriendModalClose: (state) => {
      state.addFriend = {
        isOpen: false,
        props: {} as ModalInviteStructureProps,
      };
    },
    setModalConfirmOpen: (state, action: PayloadAction<{ props: any }>) => {
      state.modalConfirm = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setModalConfirmClose: (state) => {
      state.modalConfirm = {
        isOpen: false,
        props: {},
      };
    },
    setModalJoinStructureOpen: (
      state,
      action: PayloadAction<{ props: ModalJoinStructureProps }>,
    ) => {
      state.joinStructure = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setModalJoinStructureClose: (state) => {
      state.joinStructure = {
        isOpen: false,
        props: {} as ModalJoinStructureProps,
      };
    },
    setModalLeaveStructureOpen: (
      state,
      action: PayloadAction<{ props: ModalJoinStructureProps }>,
    ) => {
      state.modalLeaveStructure = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setModalLeaveStructureClose: (state) => {
      state.modalLeaveStructure = {
        isOpen: false,
        props: {} as ModalJoinStructureProps,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTeamModalOpen,
  setTeamModalClose,
  setInviteStructureModalOpen,
  setInviteStructureModalClose,
  setInviteEventModalOpen,
  setInviteEventModalClose,
  setSendMessageStructureModalOpen,
  setSendMessageStructureModalClose,
  setSendMessagePlayerModalOpen,
  setSendMessagePlayerModalClose,
  setAddFriendModalClose,
  setAddFriendModalOpen,
  setModalConfirmOpen,
  setModalConfirmClose,
  setModalJoinStructureOpen,
  setModalJoinStructureClose,
} = modalSlice.actions;

export default modalSlice.reducer;
