import { Button, Divider } from '@mui/material';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { InvitationTypeEnum } from 'domain/invitation/InvitationTypeEnum';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { EventCard } from 'primary/Components/Event/EventCard';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
interface ConsumeEventInvitationLinkProps {
  link: InvitationLink;
  handleClose: () => void;
  nextStep: (data?: any) => void;
  data: { member: EventParticipant };
}
export const ConsumeEventInvitationLink = ({
  link,
  handleClose,
  nextStep,
}: ConsumeEventInvitationLinkProps) => {
  const {
    authRepository,
    invitationRepository,
    eventRepository,
    eventParticipantRepository,
  } = useContextDependency();
  const t = useTranslate();
  const navigate = useNavigate();

  const [event, , fetching] = useRetrieveFromDomain(
    () => eventRepository.getEventById(link.idRessource),
    undefined,
    !!link.idRessource,
  );
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  const [eventParticipant, , fetchingParticipant] = useRetrieveFromDomain(
    () =>
      eventParticipantRepository.getParticipantOfEventByProfil(
        authRepository?.currentUser?.userProfil?.id,
        link.idRessource,
      ),
    undefined,
    !!link.idRessource && !!authRepository?.currentUser,
  );

  const alreadyCreating = useRef(false);

  useEffect(() => {
    if (alreadyCreating.current) return;
    if (authRepository.currentUser && !fetchingParticipant && !eventParticipant) {
      alreadyCreating.current = true;
      invitationRepository
        .createInvitationFromLink(InvitationTypeEnum.EVENT, link.id)
        .then((member) => {
          handleClose();
          navigate(routesConfig.activities.to(), {
            state: {
              openInviteEventPending: true,
            },
          });
        });
    } else if (!!authRepository?.currentUser && !!eventParticipant) {
      alreadyCreating.current = true;
      handleClose();
      navigate(
        routesConfig.eventViewDetails.eventViewPlayersTeamOpen.to(
          link.idRessource,
          eventParticipant.id,
        ),
      );
    }
  }, [authRepository.currentUser, fetchingParticipant, eventParticipant]);



  const handleSubscribe = () => {
    authRepository.register(
      `${window.location.origin}/parameters/user/profil/apparence?invitationLink=${link.uuid}`,
    );
  };

  const handleLogin = () => {
    authRepository.login(
      `${window.location.origin}/parameters/user/profil/apparence?invitationLink=${link.uuid}`,
    );
  };

  return (
    <div className={'consumeEventInvitationLink'}>
      <h4>
        {t('invitationLink.consumedModal.event.title', {
          eventName: event?.name,
        })}
      </h4>
      {fetching && !event && (
        <LoadingScreen loading message={"Récupération du l'événement"} />
      )}
      {!fetching && !fetchingParticipant && event && (
        <div className={'-infosEvent'}>
          <EventCard event={event as UnpyEventView} />
        </div>
      )}
      <Divider />
      {!isAccountLoaded && <LoadingScreen loading message={'Récupération du compte'} />}
      {isAccountLoaded && !authRepository?.currentUser && (
        <div className={'-actions'}>
          <span>Vous devez être connecté pour continuer</span>
          <div className={'-btnContainer'}>
            <Button
              onClick={handleLogin}
              variant={'contained'}
              color={'primary'}
              type={'submit'}
            >
              {t('invitationLink.consumeModal.steps.account.loginBtn')}
            </Button>
            <Button
              onClick={handleSubscribe}
              variant={'contained'}
              color={'primary'}
              type={'submit'}
            >
              {t('invitationLink.consumeModal.steps.account.registerBtn')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
