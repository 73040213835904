import './ActionButton.scss';

import { PersonRemove } from '@mui/icons-material';
import { Button } from '@mui/material';
import { setModalConfirmClose, setModalConfirmOpen } from 'domain/modal/store/ModalSlice';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { isPrivacyRestricted } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import {
  selectConnectedUserBlocked,
  selectConnectedUserFriend,
  setRelation,
} from 'domain/relation/store/RelationSlice';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ActionButtonRemoveFriendProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const ActionButtonRemoveFriend = ({
  profil,
  withIcon,
}: ActionButtonRemoveFriendProps) => {
  const { relationRepository, authRepository } = useContextDependency();
  const t = useTranslate();
  const friends = useSelector(selectConnectedUserFriend);
  const handleClose = () => dispatch(setModalConfirmClose());
  const dispatch = useDispatch();
  const alreadyFriend = friends?.find(
    (f) => f.invited.idProfil === profil.id || f.owner.idProfil === profil.id,
  );
  const onConfirmDeleteFriend = () => {
    relationRepository.deleteById(alreadyFriend?.id as number).then(() => {
      relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
        dispatch(setRelation(relations));
        sendEventToastMessage(t('modals.deleteFriend.success'), 'success');
        handleClose();
      });
    });
  };

  const openModalAddFriend = () => {
    if (authRepository?.currentUser) {
      dispatch(
        setModalConfirmOpen({
          props: {
            title: t('profil.userProfilAction.removeFriend', { name: profil.idPlayer }),
            labelDecline: t('profil.userProfilAction.removeFriendBtnCancel'),
            labelAccept: t('profil.userProfilAction.removeFriendBtnAccept'),
            action: onConfirmDeleteFriend,
            description: t('profil.userProfilAction.removeFriendTextConfirm'),
          },
        }),
      );
    } else {
      authRepository?.login(window.location.origin);
    }
  };

  const openModalConfirmRemoveFriend = () => {
    dispatch(
      setModalConfirmOpen({
        props: {
          title: t('profil.userProfilAction.removeFriend', { name: profil.idPlayer }),
          labelDecline: t('profil.userProfilAction.removeFriendBtnCancel'),
          labelAccept: t('profil.userProfilAction.removeFriendBtnAccept'),
          action: onConfirmDeleteFriend,
          description: t('profil.userProfilAction.removeFriendTextConfirm'),
        },
      }),
    );
  };
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.idProfil === profil.id);
  const isRestricted =
    !!alreadyBlocked || isPrivacyRestricted(profil, PRIVACY_CODE.ADD_FRIEND);
  return (
    <Button
      color={'inherit'}
      variant={'text'}
      className={'-actionButton'}
      onClick={stopPropagationBeforeAction(openModalConfirmRemoveFriend)}
      disabled={isRestricted || !authRepository.currentUser}
    >
      {withIcon && <PersonRemove color={'error'} className={'-error'} />}
      {t('profil.userProfilAction.removeFriend', { name: profil.idPlayer })}
    </Button>
  );
};
