import './ActionButton.scss';

import { SportsEsports } from '@mui/icons-material';
import { Button } from '@mui/material';
import { setInviteEventModalOpen } from 'domain/modal/store/ModalSlice';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { isPrivacyRestricted } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import { selectConnectedUserBlocked } from 'domain/relation/store/RelationSlice';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRedirectUrlForAction } from 'primary/hooks/useOpenModalActionFromLogin';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ActionButtonInviteEventProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const stopPropagationBeforeAction = (action?: () => void) => (e: any) => {
  action?.();
};

export const ActionButtonInviteEvent = ({
  profil,
  withIcon,
}: ActionButtonInviteEventProps) => {
  const { authRepository } = useContextDependency();
  const t = useTranslate();
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.id === profil.id);
  const isRestricted =
    !!alreadyBlocked || isPrivacyRestricted(profil, PRIVACY_CODE.INVITE_STRUCTURE);
  const dispatch = useDispatch();
  const urlRedirect = useRedirectUrlForAction(
    'inviteEvent',
    profil.id.toString(),
    profil.idPlayer,
  );

  const openModal = () => {
    if (authRepository?.currentUser) {
      dispatch(
        setInviteEventModalOpen({
          props: { name: profil.idPlayer, userId: profil.userId },
        }),
      );
    } else {
      if (urlRedirect) {
        authRepository?.login(urlRedirect.toString());
      }
    }
  };
  return (
    <Button
      color={'inherit'}
      variant={'text'}
      className={'-actionButton'}
      onClick={stopPropagationBeforeAction(openModal)}
      disabled={isRestricted}
    >
      {withIcon && <SportsEsports />}
      {t('profil.userProfilAction.inviteEvent')}
    </Button>
  );
};
