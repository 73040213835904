import { EventConfirmationStateEnum } from 'domain/event/EventConfirmationStateEnum';
import { EventStatusEnum, EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';

export const frEventTraductions = {
  myTeamModal: {
    title: 'Mon équipe',
    playersList: 'Liste des joueurs',
    unregister: 'Se désinscrire',
    paymentRequired: "Des frais d'inscription sont à régler.",
    toPayment: 'Payer',
    openPayment: 'Payer',
    waitValid:
      "Pour que votre demande de participation soit effective votre équipe doit être compléte et tout les joueurs doivent avoir accepté l'invitation",
    waitValidate:
      "La demande de participation a été envoyé, veuillez attendre la réponse de l'organisateur.",
  },
  teamModal: {
    title: 'Équipe {{name}}',
  },
  limit: {
    create: "Vous avez atteint la limite de création d'événements par mois.",
    createSubscribeNotice: 'Devenez membre',
    createSubscribeNotice2: "pour créer plus d'événements",
    participate: 'Vous avez atteint la limite mensuel de participation aux event.',
    participateSubscribeNotice: 'Devenez membre',
    participateSubscribeNotice2: 'pour créer plus d événements',
  },
  viewPage: {
    myParticipation: {
      title: 'Ma demande de participation',
      status: {
        [EventParticipantStatusEnum.WAIT_VALIDATE]: 'En attente de validation',
        [EventParticipantStatusEnum.REGISTERED]: 'Inscription validée',
        [EventParticipantStatusEnum.REJECTED]: 'Inscription Refusée',
      },
    },
    matchs: {
      noMatchs: 'Aucuns matchs',
      noMatchNotice: {
        [EventStatusEnum.ON_GOING]:
          "Cet événement a été démarré mais l'organisateur n'a pas encore généré les matchs.",
        [EventStatusEnum.PUBLISH]:
          "Cet événement n'a pas atteint sa date de démarrage fixée au {{date}}. Vous serez notifié du démarrage de l'Événement.",
        [EventStatusEnum.CANCELED]:
          "Cet événement a été annulé sans qu'aucun match ne soit généré.",
      },
    },
    tournaments: {
      matchs: {
        title: 'Rounds & Matchs',
      },
    },
  },
  groupsSelector: {
    noGroup: 'Aucun groupe',
  },
  brPositionLabel: {
    positionBrLabel: 'Position',
  },
  participantCard: {
    nextMatchsBr: 'Matchs restants :',
    currentRount: 'Round en cours :',
    nextMatchTeamName: 'Prochain adversaire',
    nextMatchNoTeam: '--',
  },
  lastScoreCard: {
    positionBrLabel: 'Position',
  },
  podium: {
    score: 'Score: {{score}}',
    noContentParticipant: 'Non définis',
    title: 'Podium définitif ',
    noFinishTitle: 'Podium provisoire ',
    noPrice: 'Aucun prix',
    noContentList: 'Aucun podium actuellement.',
  },
  matchs: {
    edit: {
      position: 'Position',
      score: 'Score',
      filters: {
        title: 'Filtrer les événements',
        textValue: "Recherche par nom de joueur ou d'équipe",
        textValuePlaceholder: "nom de joueur ou d'équipe",
        onlyScoreUnset: 'Scores non saisis',
        onlyUpper: 'Bracket perdant',
        onlyLower: 'Bracket gagnant',
        onlyMyMatchs: 'Mes matchs uniquement',
      },
      editScore: {
        brFieldMinError: 'Le champ position doit être supérieur à 0',
        brFieldMaxError: 'Le champ position doit être inférieur à {{max}}',
        noTeam: 'Participant non définis',
        defeatScoreDefaultLabel: 'Défaite | ({{points}} points)',
        nullScoreDefaultLabel: 'Null | ({{points}} points)',
        winScoreDefaultLabel: 'Victoire | ({{points}} points)',
        modal: {
          score: 'Score',
          position: 'Position',
          teamTitle: 'Équipe {{teamName}}',
          title: 'Détails & scores',
          notice: {
            [SystemPointsEnum.PERSO]:
              'Vous avez choisi le système de score personnalisé. Veuillez rentrer les scores de chaque équipe pour valider la modification.',
            [SystemPointsEnum.DEFAULT]:
              "Vous avez choisi le système de score par défaut d'Unpy. Choisissez un résultat pour chaque équipe pour valider la modification.",
          },
          noticeBR: {
            [SystemPointsEnum.PERSO]:
              "Vous avez choisi le système de score personnalisé. Veuillez rentrer le score de l'équipe. Sa position sera calculée automatiquement par rapport aux scores des autres équipes.",
            [SystemPointsEnum.DEFAULT]:
              'Vous avez choisi le système de score par défaut Unpy. Veuillez renseigner directement la position du joueurs pour le math concerné. Un score sera calculé',
          },
        },
      },
      modal: {
        noticeNoParticipant:
          'Vous ne pouvez pas saisir de score tant que tous les participants ne sont pas alloués au match.',
        title: 'Modification du match',
        detailsTitle: 'Détails du match',
        startDateLabel: 'Date et heure de début (optionnel)',
        scoreTitle: 'Score',
        scoreLabel: 'Choix du score',
      },
    },
    numberMatchs: '{{count}} match',
    numberMatchs__plural: '{{count}} matchs',
    noMatchsInGroup: 'Aucun match dans ce groupe',
    loadingMatchInGroup: 'Récupération des matchs en cours',
    openEditScoreBtn: 'Détails',
    participantMatchTitle: 'Participants du match',
    selectGroup: {
      [TypeEventCodeEnum.LIGUE]: 'Sélectionnez un groupe',
      [TypeEventCodeEnum.TOURNAMENT]: 'Sélectionnez un Round',
      //     [TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM]: 'Sélectionnez un Round',
      [TypeEventCodeEnum.LIG_BR]: 'Sélectionnez un Match',
    },
    noGroup: 'Aucun groupe',
    lower: 'Bracekt gagnant',
    upper: 'Bracekt perdant',
  },
  register: {
    step: {
      name: "Nom de l'équipe",
      players: 'Joueurs',
      finish: 'Terminer',
    },
    createTeam: "Créer l'équipe",
    rightPanelTitle: "Création d'équipe en cours, ajoutez des joueurs",
    chooseRole: 'Choisissez un rôle',
    choosePlayer: 'Choisissez un joueur',
    cancelCreatingTeam: 'Annuler et fermer',
    addPlayerFromCard: 'Ajouter un joueur directement depuis sa carte de joueur.',
    addPlayer: 'Ajouter',
    playerNumber: 'Joueur {{number}}',
    choiceAddPlayer: 'Ajoute des joueurs via leur ID unpy',
    goMercato: 'Réduire et accéder au mercato',
    success: "Demande d'inscription envoyée avec succès",
    choiceMercato: 'Ou parcours le mercato pour trouver des joueurs',
  },
  modalReopenSubscribe: {
    title: 'Rouvrir ou repousser les inscriptions',
  },
  modalCloseSubscribe: {
    title: 'Rouvrir ou repousser les inscriptions',
    notice:
      'Êtes-vous sur de vouloir clôre les inscriptions ? La date de démarrage est fixé au {{date}}',
  },
  createEvent: 'Créer un événement',
  modalPublish: {
    title: `Publication de l'événement {{eventName}}`,
  },
  form: {
    previewAppearence: {
      titleForm: "Modifier l'apparence",
      titlePreview: 'Aperçu des modifications',
      listView: "Vue recherche d'événement",
    },
    unpyAdmin: {
      launchGarabageEvent: 'Lancer',
    },
    cancelBtn: 'Annuler',
    submitBtn: 'Créer',
    editBtn: 'Modifier',
    publishBtn: 'Publier',
    alert: {
      action: 'Modifier',
      startDateTimeAfterLimit:
        "La date de début renseignée est supérieure à J-2, vous ne pourrez plus l'éditer",
      endSubscribeDateAfterLimit:
        "La date de fin d'inscription renseignée est supérieure à J-2, vous ne pourrez plus l'éditer",
    },
    notice: {
      delayed:
        "L'événement a été reporté au {{date}}. Vous ne pouvez plus reporter cet event",
      endSubscribeDateDisabled:
        'Vous ne pouvez éditer ce champ que 48H avant la date renseignée',
      startDateDisabled:
        'Vous ne pouvez éditez ce champ que 48H avant la date renseignée',
    },
    labels: {
      name: 'Nom',
      systemPoints: 'Système de points',
      startDateTime: 'Date et heure de début',
      registerPrice: "Prix d'inscription",
      registerPriceShort: 'Inscription',
      endSubscribeDate: "Date de fin d'inscription",
      newEndSubscribeDate: "Nouvelle date de fin d'inscription",
      singleStructure: 'Une équipe par structure',
      game: 'Jeux concernés',
      typeEvent: "Type d'event",
      typeTeam: "Type d'équipe",
      maxTeam: "Nombre maximum d'équipes",
      prizeText: 'Autre récompense',
      cashprize: 'Cashprize (en €)',
      bracketTeamNumber: 'Nombre de participants',
      bracketTeamNumberOpt: '{{count}} participants',
      typeBracket: 'Type de bracket',
      rules: 'Règles',
      typeRonde: 'Type de ronde',
      freeRegister: 'Inscription libre (Pas de validation par un admins)',
      description: 'Informations complémentaires',
      image: 'Image',
      twitter: 'X / Twitter',
      facebook: 'Facebook',
      instagram: 'Instagram',
      discord: 'Discord',
      twitch: 'Twitch',
      youtube: 'Youtube',
    },
    placeholders: {
      players: 'Joueur',
      role: 'Rôle',
      name: 'Nom de votre event',
      systemPoints: 'Système de points',
      startDateTime: 'Date et heure de début',
      game: 'Sélectionnez un jeux',
      endSubscribeDate: "Date de fin d'inscription",
      singleStructure: 'Une seule équipe participante par structure',
      rules: 'Précisez les règles du tournois',
      typeEvent: "Sélectionnez un type d'event",
      typeTeam: "Sélectionnez un type d'équipe",
      typeBracket: 'Sélectionnez un type de bracket',
      typeRonde: 'Sélectionnez un type de ronde',
      description: 'Description',
      image: 'Image',
      twitter: 'X / Twitter',
      facebook: 'Facebook',
      instagram: 'Instagram',
      discord: 'Discord',
      twitch: 'Twitch',
      youtube: 'Youtube',
    },
    errors: {
      startDateAfterToday: 'La date de début doit être supérieure à la date du jour',
      endSubscribeDateAfterToday:
        "La date de fin d'inscription doit être supérieure à la date du jour",
      minStartDateTime: "La date de début doit être après la date de fin d'inscription",
      minEndSubscribeDate:
        "La date de fin d'inscription doit être avant la date de début",
    },
  },
  countDownLine: '{{day}} J {{hour}} H {{minute}} m {{second}} s',
  nextStepBtnLabel: {
    [EventStatusEnum.PUBLISH]: 'Publier',
    [EventSubStatusEnum.SUBSCRIBE_CLOSE]: 'Clôre les inscriptions',
    [EventStatusEnum.ON_GOING]: "Démarrer l'event",
    [EventStatusEnum.FINISH]: 'Terminer',
    confirmEvent: 'Confirmer la date de début',
    canceledEvent: "L'event est annulé",
    toCancelEvent:
      "L'event n'a pas été démarré à temps, il va être annulé automatiquement.",
    finishEvent: "L'event est terminé",
    clooseSubscription: 'Clôre les inscriptions manitenant',
  },
  nextInterStepBtnLabel: {
    countdown: "Vous pourrez démarrer l'event dans ",
    countdownForConfirm: 'Demande de validation de la date de début dans ',
    waitConfirm: 'Date de début en attente de confirmation',
  },
  nextStepHelper: {
    [EventStatusEnum.PUBLISH]:
      'Publier votre événement nécéssite le renseignement de certains champs obligatoires et permet de rendre votre événement visible par les autres joueurs dans la section Events.',
    [EventSubStatusEnum.SUBSCRIBE_CLOSE]:
      "Les joueurs ne pourront plus s'inscrire a votre événement. Les équipes inscrites seront prévenues avant le début de l'événement.",
    [EventStatusEnum.ON_GOING]:
      'Démarre votre événement. Tous les participants seront prévenus et un classement sera généré et accessible dans la section Classement des paramètres de votre event.',
    [EventStatusEnum.FINISH]:
      "Terminer votre événement. Le classement ne seras plus éditable, et les participants seront prévenus de la fin de l'événement.",
  },
  nextStepConfirm: {
    [EventStatusEnum.DRAFT]: {
      confirmedStartSubscribeDate: 'Veuillez confirmer la date de début des inscriptions',
    },
  },
  reminds: {
    eventObligations:
      "La publication d'événement implique des responsabilités de gestion dont l'organisateur doit s'acquitter. ",
  },
  filters: {
    placeAvailable: 'Inscription possible',
    displayFinished: 'événements terminés',
    title: 'Filtres',
    validate: 'Valider',
    status: 'Statut',
    game: 'Jeu',
    games: 'Jeux',
    startDateTime: 'Date de début',
    name: 'Nom',
    typeEvent: "Type d'event",
    typeTeam: "Type d'équipe",
    platform: 'Plateforme',
  },
  card: {
    skeleton: {
      addOwn: 'Ajoutez votre événement',
    },
  },
  bracket: {
    matchView: {
      customMatch: {
        undoTitle: 'Annuler le résultat',
      },
    },
    modalView: {
      getMatchsMsgLoading: 'Récupération des matchs',
    },
  },
  timeline: {
    next: "Passer à l'étape {{nextStatus}}",
    goToOnGoing: 'Démarrer le tournois et générer les matchs.',
    actions: {
      noAction: 'Aucune action possible',
      label: 'Actions',
      reopenSubscribe: {
        titleModal: 'Rouvrir les inscriptions',
        label: 'Rouvrir les inscriptions',
      },
      closeSubscribe: {
        titleModal: 'Clôre les inscriptions',
        label: 'Clôre les inscriptions',
      },
      start: {
        wainting: "Vous pourrez démarrer l'événement à partir du {{date}}",
        label: "Démarrer l'événement",
      },
      validateStart: {
        label: 'Confirmer la date de début',
        wainting: 'Vous pourrez confirmer la date de début à partir du {{date}}',
      },
      finish: {
        titleModal: 'Terminer l événement',
        label: 'Terminer l événement',
      },
      cancel: {
        titleModal: 'Annuler l événement',
        label: 'Annuler l événement',
      },
      publish: {
        label: 'Publier l événement',
      },
    },
  },
  modal: {
    generateClassement: {
      form: {
        title: 'Paramètres de génération des matchs',
      },
      typeEvent: {
        label: 'Type de classement',
      },
      typeTeam: {
        label: "Type d'équipe",
      },
      participants: {
        label: 'Participants',
      },
      game: {
        label: 'Jeu',
      },
      titleParams: 'Paramètres de génération des matchs : ',
      [TypeEventCodeEnum.TOURNAMENT]: {
        explanation:
          'Unpy va générer les rounds et les matchs en attribuant les participant aléatoirment.',
        warning:
          "Attention, vous n'avez pas atteint le nombre de participant voulu {{maxParticipants}}. Unpy va combler les places restantes avec {{nbFictive}} équipe(s) fictives déclarée(s) forfait.",
      },
      // [TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM]: {
      //   explanation:
      //     'Unpy va générer les rounds et les matchs en attribuant les participant aléatoirment.',
      //   warning:
      //     "Attention, vous n'avez pas atteint le nombre de participant voulu {{maxParticipants}}. Unpy va combler les places restantes avec {{nbFictive}} équipe(s) fictives déclarée(s) forfait.",
      // },
      [TypeEventCodeEnum.LIGUE]: {
        explanation:
          'Unpy va générer le nombre de matchs nécessaire pour que chaque équipe affronte toutes les autres équipes une fois.',
      },
      [TypeEventCodeEnum.LIG_BR]: {
        labelTotalMatch: 'Nombre de matchs souhaité',
        explanation:
          "Unpy va générer des matchs contenant chacun la totalité des participants a l'événement.",
      },
      typeTournois: {
        label: 'Type de tournois',
        simpleElem: 'Simple élimination',
        doubleElem: 'Double élimination',
      },

      ronde: {
        robinLabel: 'Ronde robin',
        label: 'Ronde',
      },
    },
    confirmEditStartDate: {
      title:
        'Êtes-vous sur de voulour modifier la date de début alors que votre évenement est deja plublié ?',
      notice:
        "La modification de la date de début après publication de l'événement ne peux se faire qu'une fois, le tournois sera considéré comme Reporté au {{date}}. Trop de tournois reportés peuvent impliquer une suspension de votre compte.",
    },
    confirmRegisterPrice: {
      title: "Êtes-vous sur de voulour attribuer un prix d'inscription ?",
      notice:
        "Unpy n'est pas responsable du non respect des loi en vigueur dans le paus où l'Événement est organisé. Vous devez vous assurer de respecter les lois en vigueur dans votre pays.",
    },
    confirmEvent: {
      title: "Confirmation du démarrage de l'événement",
      infos: 'Vous pouvez repousser cette date une seule fois.',
      alertDelayed:
        'Le fait de repousser la date de début catégorise le tournois comme reporté. Trop de tournois reportés peuvent impliquer une suspension de votre compte.',
      alertCanceled:
        "Le fait d'annuler trop  d'événements peux impliquer une suspension de votre compte.",
      confirmBtn: 'Confirmer la date',
      delayedBtn: "Retarder L'événement",
      canceledBtn: "Annuler L'événement",
    },
    startEvent: {
      title: 'Démarrer l événement',
    },
    closeSubscribeAndStartEvent: {
      title: "Clôre les inscriptions et démarrer l'événement",
    },
    modalClassementView: {
      fetching: 'Récupération du classement',
      titleModal: "Classement de l'événement {{eventName}}",
      errorStatus:
        'Le statut de cet événement ne permet pas de visualiser un classement.',
    },
    register: "S'inscrire à l'événement",
    registerTeam: 'Composez votre équipe pour vous inscrire',
    nameLabel: 'Nom de votre équipe',
    selectStructureHelper: "Participer au nom d'une structure ?",
    selectStructureHelperNotice:
      'Seul les joueurs de cette structure pourront faire partie de cette équipe.',
    namePlaceholder: 'Renseignez le nom de votre équipe',
    validationRegisterNotice:
      "Une demande de participation sera envoyée aux admins de l'événement une fois que tous les joueurs de l'équipe auront accepté l'invitation",
    freeRegisterNotice:
      "Votre équipe sera automatiquement ajoutée à l'événement une fois que tous les joueurs de l'équipe auront accepté l'invitation",
  },
  eventViewPage: {
    rules: {
      title: 'Règles & Infos complémentaires',
    },
    header: {
      textInfos: {
        startDate: 'Début : {{date}}',
        startDateText: 'Début',
        startDatePassed: 'Démarrage en attente depuis :',
        endSubscribe: 'Fin des inscriptions : {{date}}',
        endSubscribeText: 'Fin des inscriptions',
        typeEvent: 'Type :',
        typeTeam: 'Équipe :',
        status: 'Statut :',
        endSubscribeDate: 'Fin des inscriptions dans :',
        endSubscribeDateShort: 'Clôture :',
        remainingPlace: 'Places :',
        remainingPlaceFormatted: '{{count}} / {{maxTeam}} participants',
        notConfirmStatus: 'Date de début en attente de confirmation',
        game: 'Jeu :',
        prize: 'Prix :',
        noPrize: 'Aucun prix',
        noCashprize: 'Aucun cashprize',
        cashprize: 'Cashprize',
        registerPrice: 'Inscription :',
        noRegisterPrice: 'Gratuit',
      },
    },
    annonces: {
      title: 'Annonces',
    },
    players: {
      title: 'Équipes inscrites',
    },
    scoreboard: {
      title: 'Classement',
      editScore: 'Détails & score',
    },
    matchs: {
      title: 'Matchs',
    },
    menu: {
      links: {
        matchs: 'Matchs',
        annonces: 'Annonces',
        infos: 'Informations',
        timeline: 'Timeline',
        players: 'Participants',
        scoreboard: 'Classement',
      },
    },
    networks: {
      title: 'Réseaux sociaux',
    },
    moreInfos: {
      title: 'Informations complémentaires',
      noContent: 'Aucunes informations complémentaires renseignées',
    },
    socials: {
      title: 'Réseaux sociaux',
    },
    admins: {
      title: 'Administrateur',
    },
    podiumView: {
      title: 'Podium',
      seeAllClassement: 'Voir le classement complet',
      noPodium: 'Aucun classement actuellement',
    },
    confirmStatusText: {
      [EventConfirmationStateEnum.WAITING]: 'Confirmation en attente',
      [EventConfirmationStateEnum.CONFIRMED]: 'Début confirmé',
      [EventConfirmationStateEnum.DECLINE]: 'Annulé',
      [EventConfirmationStateEnum.DELAYED]: 'Reporté : {{date}}',
    },
    status: 'Statut :',
    noStructureNotice:
      "Vous devez faire partie d'une structure pour participer à un événement",
    mercatoStructureBtn: 'Rechercher une structure',
    createStructureBtn: 'Créer une structure',
    registerBtn: 'Participer',
    subscribeForRegister: 'Inscrivez-vous pour participer',
    seeTeam: 'Voir mon équipe',
    parametersBtn: 'Paramètrer',
    nbRegistered: '{{count}} / {{maxTeam}} inscriptions',
    description: {
      title: 'Description',
    },
    infos: {
      title: 'Informations',
      timelineTitle: 'Timeline',
      rules: {
        title: 'Règles',
        noRules: 'Aucunes règles renseignées',
      },
      endSubscribeDate: "Date de fin d'inscription :",
      startDateTime: 'Date de début :',
      maxTeam: "Nombre d'équipes max :",
      registerPrice: "Coût d'inscription :",
      cashPrize: 'Cashprize :',
      description: 'Description :',
      game: 'Jeu :',
      details: 'Détails',
      freeRegister: 'Inscription libre :',
      freeRegisterValue: 'Libre',
      freeRegisterValidationValue: 'Validation requise',

      teams: 'Équipes',
      parameters: 'Paramètres',
      mode: 'Mode :',
      teamType: "Type d'équipes :",
      pointsSystem: 'Système de points :',
      yes: 'oui',
      no: 'non',
    },
  },
  eventsListPage: {
    titleCaroussel: 'Sélectionnez les jeux',
    titleEventList: 'Rechercher un événement',
    titleEventListOngoing: 'Rechercher un événement',
    titleEventListFinished: 'Rechercher un événement',
    titleEvents: 'événements',
  },
  eventsPage: {
    selectGamePageTitle: 'Sélectionnez un jeu',
    tournamentFamous: 'Tournois populaire',
    tournamentFuture: 'Tournois à venir',
    goBack: 'Retour',
  },
  listPage: {
    tabs: {
      ongoing: 'En cours',
      finished: 'Terminé',
    },
  },
  list: {
    noContent: {
      noContentMsg: 'Aucun event',
      btnSearchEvent: 'Rechercher un event',
    },
  },
  teams: {
    datatable: {
      statusRefused: 'Refusé',
      statusWaiting: 'En attente',
      statusWaitingPlayer: 'En attente de joueur',
      statusAccepted: 'Inscrite',
      deleteAction: 'Supprimer',
      noAction: 'Aucune action possible',
      declineAction: 'Refuser',
      leaveTeamNotice: "Le chef d'équipe ne peux pas quitter l équipe",
      deletePlayer: "Supprimer de l'équipe",
      leaveTeam: "Quitter l'équipe",
      acceptAction: 'Accepter',
      seeTeam: 'Voir mon équipe',
      labelName: 'Équipe',
      labelTop1: '1 /  3 / 50%',
      labelTop3: 'Top 3',
      labelTopHalf: 'Top 50%',
      labelVictories: 'V / N / D',
      labelDefeats: 'D',
      labelNulls: 'N',
      labelPosition: 'Position',
      labelPoints: 'points',
      noScoreDefault: 'saisissez une position',
      noPositionPerso: 'saisissez un score',
      labelPerso: 'Position',
      labelScore: 'Score',
      labelTeam: 'Équipe',
      labelRole: 'Rôle',
      position: 'Nom',
      labelPlayers: 'Joueurs',
      labelStatut: 'Statut',
      labelActions: 'Actions',
      copyLink: "Copié le lien d'invitation",
      noContentLabel: 'Aucunes équipes',
    },
  },
  parameters: {
    timeline: {
      infos: {
        title: "Cycle de vie d'un événement",
        subTitle: 'Choissez une étape pour voir les informations associées',
      },
      title: 'Timeline',
      currentStateExplain: {
        [EventStatusEnum.DRAFT]:
          "L'événement est en brouillon, il n'est pas public. Tout les champs sont modifiables, et personne ne peux s'inscrire.",
        [EventStatusEnum.PUBLISH]:
          "L'événement est visible pour tous et deux sous-états sont possibles :",
        [EventStatusEnum.CANCELED]: "L'évenement a été annulé. Aucune action possible.",
        [EventStatusEnum.FINISH]:
          "L'évennement est terminé ! Vous avez la possibilité d'envoyer le cashprize au gagnant et de retirer les fonds relatifs aux inscriptions dans le cas ou un prix d'inscription a été paramètré",
        [EventStatusEnum.ON_GOING]:
          "L'évennement est en cours, il n'est plus possible pour les joueurs de s'inscrire et la plupart des paramètres de l'événnement ne sont plus éditables. Les matchs ont été générés, vous pouvez dés à présent organiser les rencontres et saisir les scores.",
        [EventSubStatusEnum.SUBSCRIBE_OPEN]:
          "les inscriptions sont ouvertes et les joueurs peuvent s'inscrire. Les champs relatifs au paramètrage de l'event ne sont plus éditable.",
        [EventSubStatusEnum.SUBSCRIBE_CLOSE]:
          "L'évennement est publié et visible cependant les inscriptions sont fermées, soit car la date de fin d'inscription est dépassée, soit car le nombre de joueur est atteint. L'administrateur de l'événement peux démarrer le tournois et générer les matchs à partir de la date de début renseignée.",
      },
    },
    getEventMsgLoading: "Récupération de l'event",
    title: 'Paramètres event',
    classements: {
      participantLow: 'Pas assez de participant.',
      canceledWithoutBoard:
        "L'event a été annulé sans qu'aucun classement ne soit généré",
      noMatchGenerated: "Aucun matchs n'a encore été généré.",
      infoStatus:
        "Votre événement n'a pas atteint sa date de démarrage fixée au {{date}}. Vous pouvez modififer cette date dans les paramètres de votre événement jusqu'à 48h à l'avance.",
      title: 'Classements',
      noStatus: 'Les inscriptions doivent être closes pour ajouter un classement',
    },
    participants: {
      title: 'Équipes & demandes de participations',
    },
    myParticipation: {
      title: 'Ma demande de participation',
    },
    classement: {
      title: 'Classement',
    },
    appearence: {
      title: 'Apparence',
    },
    unpyAdmin: {
      title: 'Administration via membre Unpy',
    },
    parameterize: {
      title: 'Paramètrage',
    },
    rules: {
      title: 'Règles',
      updateSuccess: 'Event mis à jours avec succès',
    },
  },
  ligue: {
    pouleLabel: 'Groupe {{suffixe}}',
  },
};
