import './TimelineEvent.scss';

import {
  Cancel,
  Check,
  EmojiEvents,
  ModeEdit,
  PlayArrow,
  Public,
} from '@mui/icons-material';
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import { Orientation } from '@mui/material/Stepper/Stepper';
import classNames from 'classnames';
import { EventStatusEnum, STEPPER_CONF } from 'domain/event/EventStatusEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { getSubStatusOfEvent } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { findIndex } from 'lodash';
import MenuActionTimeline from 'primary/Components/Event/MenuActionTimeline';
import { SkeletonNoContent } from 'primary/Components/SkeletonNoContent/SkeletonNoContent';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { UnpyEventAction } from 'primary/context/event/Event.reducer';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import React, { Dispatch, useCallback, useContext, useEffect, useState } from 'react';

import { EVENT_CONTEXT } from '../../context/event/EventContext';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate } from '../../hooks/useTranslate';

type TimelineEventProps = {
  orientation: Orientation;
  mobile?: boolean;
  readonly?: boolean;
  onSelectStepInfo?: (stepCode: EventStatusEnum) => void;
  selectedIdentifierInfos?: EventStatusEnum;
};

type TimelineEventCompProps = {
  orientation: Orientation;
  mobile?: boolean;
  event?: UnpyEvent | UnpyEventView;
  dispatch?: Dispatch<UnpyEventAction>;
  withoutActions?: boolean;
  readonly?: boolean;
  withoutDraftState?: boolean;
  onSelectStepInfo?: (stepCode: EventStatusEnum) => void;
  selectedIdentifierInfos?: EventStatusEnum;
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,#ff7800 0%, #ff7800 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,#ff7800 0%, #ff7800 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 95deg,#ff7800 0%, #ff7800 100%)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 90deg,#c2c2c254 0%, #c2c2c254 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: completed ? <Check /> : <ModeEdit />,
    2: completed ? <Check /> : <Public />,
    3: completed ? <Check /> : <PlayArrow />,
    4: completed ? <Check /> : <EmojiEvents className={'-podium--first'} />,
    5: completed ? <Check /> : <Cancel />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const TimelineEventWrapperContextParam = ({
  orientation,
  mobile,
  readonly,
  onSelectStepInfo,
  selectedIdentifierInfos,
}: TimelineEventProps) => {
  const { event, dispatch } = useContext(EVENT_CONTEXT);
  return (
    <SkeletonNoContent displayContent={!!event}>
      {event && (
        <TimelineEventComp
          onSelectStepInfo={onSelectStepInfo}
          event={event}
          dispatch={dispatch}
          orientation={orientation}
          readonly={readonly}
          selectedIdentifierInfos={selectedIdentifierInfos}
        />
      )}
    </SkeletonNoContent>
  );
};

export const TimelineEventComp = ({
  orientation,
  event,
  dispatch,
  readonly,
  onSelectStepInfo,
  selectedIdentifierInfos,
  withoutDraftState,
}: TimelineEventCompProps) => {
  const { eventRepository } = useContextDependency();
  const [activeStep, setActiveStep] = useState(
    event ? findIndex(STEPPER_CONF.steps, (value) => value.name === event.status) : 0,
  );

  const refreshEvent = useCallback(() => {
    if (readonly) return;
    eventRepository.getEventById(event?.id as number).then((value: UnpyEvent) => {
      dispatch?.({ type: 'SET_EVENT', event: value });
    });
  }, [event?.id]);

  const [activeSubStep, setActiveSubStep] = useState(
    activeStep && event
      ? STEPPER_CONF.steps[activeStep].subSteps?.findIndex(
          (value) => value.name === getSubStatusOfEvent(event),
        )
      : undefined,
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleCancel = (event: React.MouseEvent<HTMLDivElement>) => {
    if (readonly) return;
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCancelEvent = () => {
    if (readonly) return;
    eventRepository.cancelEvent(event?.id as number).then(() => {
      refreshEvent();
      sendEventToastMessage(t('general.edit.success'), 'success');
      setOpen(false);
    });
  };

  const t = useTranslate();

  useEffect(() => {
    setActiveStep(
      event ? findIndex(STEPPER_CONF.steps, (value) => value.name === event.status) : 0,
    );
    setActiveSubStep(
      activeStep && event
        ? STEPPER_CONF.steps[activeStep].subSteps?.findIndex(
            (value) => value.name === getSubStatusOfEvent(event),
          )
        : undefined,
    );
  }, [event]);
  if (!event) return null;

  return (
    <div
      className={classNames('timelineEvent', {
        '-vertical': orientation === 'vertical',
      })}
    >
      <Stepper
        orientation={orientation}
        activeStep={activeStep}
        className={'-mainTimelineStepper'}
        connector={<ColorlibConnector />}
      >
        {STEPPER_CONF.steps
          .filter((s) => (withoutDraftState ? s.name !== EventStatusEnum.DRAFT : true))
          .map((step, index) => {
            const stepActive = event.status.toString() === step.name;
            const isCancelStep = step.name === EventStatusEnum.CANCELED;
            const stepProps: { completed?: boolean; active?: boolean } = {};
            const subStep = activeSubStep ? step.subSteps?.[activeSubStep] : undefined;

            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            const labelTraduct = t(`enum.eventStatus.${step.name}`);
            // @ts-ignore
            const labelSubTraduct = t(`enum.eventSubStatus.${subStep?.name}.title`);

            return (
              <Step
                key={labelTraduct}
                {...stepProps}
                className={classNames('timelineEvent__step', {
                  '-withSubStep': step.subSteps && step.subSteps.length > 0,
                  '-cancelStepAction':
                    isCancelStep && event.status !== EventStatusEnum.CANCELED,
                  '-activeStep': stepActive,
                  '-activeInfoStep': selectedIdentifierInfos === step.name,
                })}
                onClick={(e) => {
                  onSelectStepInfo?.(step.name);
                  isCancelStep && event.status !== EventStatusEnum.CANCELED
                    ? handleCancel(e)
                    : undefined;
                }}
                disabled={isCancelStep && event.status === EventStatusEnum.CANCELED}
              >
                <StepLabel
                  {...labelProps}
                  className={classNames('timelineEvent__step-label')}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {labelTraduct}
                  {subStep && labelSubTraduct && (
                    <>
                      <span className={'labelSubSteps'}>({labelSubTraduct})</span>
                    </>
                  )}
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
      {!readonly && (
        <>
          <MenuActionTimeline event={event} refreshEvent={refreshEvent} />
          <ModalConfirm
            handleClose={() => setOpen(false)}
            isOpen={open}
            title={'Êtes-vous sur de vouloir annuler cet Événement ?'}
            description={
              "L'anulation est définitive. Trop d'anulation d'event démarrés peut entrainer une suspension de votre compte."
            }
            action={handleCancelEvent}
            labelAccept={"Oui, annuler l'Événement"}
            labelDecline={'Non'}
          />
        </>
      )}
    </div>
  );
};

// interface ButtonNextStepEventProps {
//   event: UnpyEvent;
//   refreshEvent: () => void;
// }
//
// export const useCallApiNextStepEvent = (
//   event: UnpyEvent,
//   onSubmitted: (event: UnpyEvent) => void,
//   openEventPublish: () => void,
//   data?: GenerateMatchesFormData,
// ) => {
//   const { eventRepository } = useContextDependency();
//   switch (event.getNextStatusOrSubStatus()) {
//     case EventStatusEnum.PUBLISH:
//       return openEventPublish;
//     case EventSubStatusEnum.SUBSCRIBE_CLOSE:
//       return () => eventRepository.closeSubscriptionEvent(event.id).then(onSubmitted);
//     case EventStatusEnum.ON_GOING:
//       return () =>
//         eventRepository
//           .startEvent(event.id, data as GenerateMatchesFormData)
//           .then(onSubmitted);
//     case EventStatusEnum.FINISH:
//       return () => eventRepository.finishEvent(event.id).then(onSubmitted);
//     default:
//       return undefined;
//   }
// };

// export const ButtonNextStepEvent = ({
//   event,
//   refreshEvent,
// }: ButtonNextStepEventProps) => {
//   const nextStep = event.getNextStatusOrSubStatus();
//   const t = useTranslate();
//
//   const canConfirmStartDate =
//     nextStep === EventStatusEnum.ON_GOING &&
//     (event.confirmationState === EventConfirmationStateEnum.WAITING ||
//       !event.confirmationState);
//
//   const canDisplayCountDown = false;
//
//   const canStart =
//     nextStep === EventStatusEnum.ON_GOING &&
//     event.confirmationState === EventConfirmationStateEnum.CONFIRMED &&
//     isAfter(event.startDateTime, new Date());
//
//   const canFinish = nextStep === EventStatusEnum.FINISH;
//   const canCloseSubscription = nextStep === EventSubStatusEnum.SUBSCRIBE_CLOSE;
//
//   const nextStepCallClick = useCallApiNextStepEvent(event, onSubmitted, openModalPublish);
//   const navigate = useNavigate();
//   return (
//     <>
//       <Button
//         className={'btnNextStepEvent'}
//         color={'primary'}
//         style={{ justifySelf: 'flex-end' }}
//         variant={'contained'}
//         disabled={disabled}
//         onClick={getOnClickConfirm()}
//       >
//         {event.confirmationState !== EventConfirmationStateEnum.CONFIRMED && (
//           <>
//             {event.status === EventStatusEnum.CANCELED &&
//               t(`event.nextStepBtnLabel.canceledEvent`)}
//             {nextStep === EventStatusEnum.CANCELED &&
//               t(`event.nextStepBtnLabel.toCancelEvent`)}
//             {event.status === EventStatusEnum.FINISH &&
//               t(`event.nextStepBtnLabel.finishEvent`)}
//             {canCloseSubscription && t(`event.nextStepBtnLabel.clooseSubscription`)}
//             {!canStart &&
//               canConfirmStartDate &&
//               t(`event.nextStepBtnLabel.confirmEvent` as TranslationType)}
//           </>
//         )}
//         {event.confirmationState === EventConfirmationStateEnum.CONFIRMED && (
//           <>
//             {canDisplayCountDown && (
//               <div>
//                 {t('event.nextInterStepBtnLabel.countdown')}
//                 <CountDown isShort targetDate={event.startDateTime} />
//               </div>
//             )}
//             {(canStart || canFinish) &&
//               t(`event.nextStepBtnLabel.${nextStep}` as TranslationType)}
//           </>
//         )}
//       </Button>
//     </>
//   );
// };
