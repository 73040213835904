import { ExitToApp } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

import { AccountInfosForm } from './AccountInfosForm';

export const Account = () => {
  const t = useTranslate();
  const { userProfilRepository } = useContextDependency();

  const onClickReset = () => {
    userProfilRepository.askNewPassword().then(() => {
      sendEventToastMessage('general.passwordreset.success', 'success');
    });
  };
  return (
    <PageLayout>
      <Title
        title={t('user.parameters.account.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <h3>{t('user.parameters.account.editInfos')}</h3>
          <AccountInfosForm />
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>{t('user.parameters.account.resetPassword')}</h3>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={onClickReset}
            className={'cancel'}
          >
            <ExitToApp />
            {t('user.parameters.account.resetPasswordBtn')}
          </Button>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
