import '../FilterTalkLang.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectTag } from 'primary/Components/Input/InputSelectTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import React from 'react';

import { useTranslate } from '../../../hooks/useTranslate';

type FilterTalkLangProps = {
  onSelectAge: (filterType: AGE_CRIT, values?: number) => void;
  selectedAge?: {
    type: AGE_CRIT;
    value?: number;
  };
};

type AGE_OPT_LABEL = 'AGE_LESS_18' | 'AGE_MORE_18' | 'AGE_MORE_25';
export type AGE_CRIT = 'MAX_AGE' | 'MIN_AGE';

type AGE_OPT = {
  label: AGE_OPT_LABEL;
  value: number;
  filterType: AGE_CRIT;
};

const AGE_OPTIONS: AGE_OPT[] = [
  {
    label: 'AGE_LESS_18',
    value: 18,
    filterType: 'MAX_AGE',
  },
  {
    label: 'AGE_MORE_18',
    value: 18,
    filterType: 'MIN_AGE',
  },
  {
    label: 'AGE_MORE_25',
    value: 25,
    filterType: 'MIN_AGE',
  },
];

export const FilterAge = ({ onSelectAge, selectedAge }: FilterTalkLangProps) => {
  const t = useTranslate();
  return (
    <div>
      <InputSelectTag
        placeholder={t('mercato.filters.placeholders.age')}
        options={insertEmptyOpt(
          AGE_OPTIONS?.map((age: AGE_OPT) => ({
            label: t(`mercato.filters.player.valueLabel.age.${age.label}`),
            value: age.filterType + '#' + age.value?.toString(),
          })) || [],
        )}
        onChange={(event: ChangeHandlerTypeMeta) => {
          //ts-ignore
          const type = AGE_OPTIONS.find(
            (age) => age.value === event.target.value,
          )?.filterType;
          onSelectAge(
            (event.target.value as string)?.split('#')?.[0] as AGE_CRIT,
            (event.target.value as string)?.split('#')?.[1] as unknown as number,
          );
        }}
        value={selectedAge?.type + '#' + selectedAge?.value?.toString()}
        menuPortalTarget={document.body}
      />
    </div>
  );
};
