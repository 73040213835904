import './NetworkForm.scss';

import { Button, Grid, Switch, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { ProfilPrivacy } from 'domain/privacy/ProfilPrivacy';
import { getInitialValuesUserProfilNetwork } from 'domain/profil/UserProfil.func';
import { IUserProfilRepository } from 'domain/user/UserProfil.repository';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { SocialNetworkFormsWrapper } from 'primary/Components/NetworkForms/SocialNetworkFormsWrapper';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { themeParameters } from '../../parameters.helper';
import { useUserParameters } from '../useUserParameters.hook';

export type UserProfilNetworkFormFormData = {
  video?: string;
  ping?: boolean;
  twitter?: string;
  facebook?: string;
  youtube?: string;
  instagram?: string;
  discord?: string;
  twitch?: string;
  privacies: {
    [key: string]: {
      profilId: number;
      code: string;
      isChecked: boolean;
    };
  };
};
export const useOnSubmit =
  (userProfilRepository: IUserProfilRepository, onSubmitted: () => void) =>
  (data: UserProfilNetworkFormFormData) => {
    userProfilRepository.updateProfilNetwork(data).then(onSubmitted);
  };

const validationSchema = (t: UseTranslateReturn) => ({
  video: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .url(t('parameters.menus.profil.user.form.errors.videoProfilUrl')),
  twitter: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  facebook: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  youtube: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  instagram: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  discord: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  twitch: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
});

export const UserProfilNetworkForm = () => {
  const { user, onSubmitted, userProfilRepository } = useUserParameters();
  const t = useTranslate();

  const initialValues = useMemo(
    () => getInitialValuesUserProfilNetwork(user?.userProfil),
    [user?.userProfil],
  );

  // @ts-ignore
  const shape = (user?.userProfil?.privacies as ProfilPrivacy[]).reduce(
    (acc, privacy) => {
      return {
        ...acc,
        [privacy.code]: yup.object().shape({
          code: yup
            .string()
            .max(
              ValidationStringLengthEnum.DEFAULT,
              t('general.forms.errors.tooLong', {
                maxChar: ValidationStringLengthEnum.DEFAULT,
              }),
            )
            .required(t('general.forms.errors.required')),
          isChecked: yup.bool().required(t('general.forms.errors.required')),
        }),
      };
    },
    {},
  );

  const resolver = useYupValidationResolver(
    yup.object().shape({ ...validationSchema(t), privacies: yup.object().shape(shape) }),
  );

  const form = useForm<UserProfilNetworkFormFormData>({
    resolver,
    defaultValues: initialValues,
  });

  const onSubmit = useMemo(
    () => useOnSubmit(userProfilRepository, onSubmitted),
    [userProfilRepository, onSubmitted],
  );

  if (!user?.userProfil) return null;
  return (
    <div className={'UserProfilNetworkForm'}>
      <PageLayout>
        <Title
          title={t('user.parameters.network.title')}
          level={1}
          compensatePadding
          threeQuarter
          bordered
        />
        <form name={'network-profil'} onSubmit={form.handleSubmit(onSubmit)}>
          <ThemeProvider theme={themeParameters}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <InputWrapper
                  label={t('parameters.menus.profil.user.form.videoProfilLabel')}
                  errors={form.formState.errors}
                  inputClassName={'input-user-details '}
                  labelClassName={'label-user-details '}
                  isSubmitting={form.formState.isSubmitting}
                  Input={TextField}
                  inputProps={{
                    placeholder: t(
                      'parameters.menus.profil.user.form.videoProfilPlaceholder',
                    ),
                    disabled: form.formState.isSubmitting,
                    ...form.register('video', { required: true }),
                    variant: 'filled',
                  }}
                />
                <SocialNetworkFormsWrapper form={form} initialValues={initialValues} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <h3>
                  Paramètres de ping <br />
                  <small>J'autorise les autres joueurs à : </small>
                </h3>
                {user?.userProfil?.privacies
                  ?.sort((a, b) => a.id - b.id)
                  ?.map((privacy) => (
                    <Grid item xs={6} key={privacy.code}>
                      <InputWrapper
                        //@ts-ignore
                        label={t(`privacy.${privacy.code}.labelForm`)}
                        errors={form.formState.errors}
                        className={'unpy-form__field'}
                        isSubmitting={form.formState.isSubmitting}
                        Input={Switch}
                        noFullWidth
                        inputProps={{
                          ...form.register(`privacies.${privacy.code}.isChecked`),
                          //@ts-ignore
                          defaultChecked:
                            //@ts-ignore
                            initialValues.privacies[privacy.code]?.isChecked,
                          onChange: (e) => {
                            form.setValue(
                              `privacies.${privacy.code}.isChecked`,
                              e.target.checked,
                            );
                          },
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
              <Grid item xs={12} justifyContent={'center'}>
                <Button
                  color={'primary'}
                  style={{ justifySelf: 'flex-end' }}
                  variant={'contained'}
                  type={'submit'}
                >
                  {t('parameters.menus.profil.user.form.submit')}
                </Button>
              </Grid>
            </Grid>
          </ThemeProvider>
        </form>
      </PageLayout>
    </div>
  );
};
