import './ActionButton.scss';

import { PersonAdd } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { setModalConfirmClose, setModalConfirmOpen } from 'domain/modal/store/ModalSlice';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { removeOneConnectedUserStructureProfil } from 'domain/user/store/UserSlice';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch } from 'react-redux';

interface ActionButtonLeaveStructureProps {
  profil: StructureProfil;
  withIcon?: boolean;
}

export const ActionButtonLeaveStructure = ({
  profil,
  withIcon,
}: ActionButtonLeaveStructureProps) => {
  const t = useTranslate();
  const { structureMemberRepository, authRepository } = useContextDependency();
  const handleClose = () => dispatch(setModalConfirmClose());
  const dispatch = useDispatch();
  const onConfirmDeleteMember = () => {
    structureMemberRepository
      //@ts-ignore
      .deleteMember(authRepository.currentUser?.id, profil?.structure?.id)
      .then(() => {
        dispatch(removeOneConnectedUserStructureProfil(profil));
        handleClose();
      });
  };

  const openModalConfirmLeaveStructure = () => {
    if (authRepository?.currentUser) {
      dispatch(
        setModalConfirmOpen({
          props: {
            title: t('profil.userProfilAction.leaveStructureTitle', {
              name: profil.name,
            }),
            labelDecline: t('profil.userProfilAction.leaveStructureBtnCancel'),
            labelAccept: t('profil.userProfilAction.leaveStructureBtnAccept'),
            action: onConfirmDeleteMember,
            description: t('profil.userProfilAction.leaveStructureTextConfirm'),
          },
        }),
      );
    } else {
      authRepository?.login(window.location.href);
    }
  };

  return (
    <>
      <LoadingButton
        color={'inherit'}
        variant={'text'}
        className={'-actionButton'}
        onClick={stopPropagationBeforeAction(openModalConfirmLeaveStructure)}
        disabled={!authRepository.currentUser?.id}
      >
        {withIcon && <PersonAdd />}
        {t('profil.userProfilAction.leaveStructure')}
      </LoadingButton>
    </>
  );
};
