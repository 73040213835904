import {
  selectBackgroundImageById,
  setBackgroundImageById,
} from 'domain/backgroundImage/store/BackgroundImageSlice';
import { Neon } from 'domain/neon/Neon';
import { selectNeonById, setNeonById } from 'domain/neon/store/NeonSlice';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import {
  useRetrieveFromDomain,
  useRetrieveFromDomainToContext,
} from 'primary/hooks/useRetrieveFromDomain';
import { UseTranslateReturn } from 'primary/hooks/useTranslate';
import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import { RootState } from 'store';

export const useCanCreateAnnoncementStructure = (structureId?: number | string) => {
  const { structureRepository, authRepository } = useContextDependency();
  const [canCreate] = useRetrieveFromDomain(
    () => structureRepository.canCreateAnnoncement(structureId as number),
    undefined,
    !!structureId && !!authRepository?.currentUser,
  );
  if (!authRepository?.currentUser) return false;
  return canCreate;
};

export const useGenerateDraftFromAppearence = (
  appearence: ProfilAppearenceFormData,
  profil?: StructureProfil,
): StructureProfil | undefined => {
  if (!profil) return undefined;

  const { backgroundImageRepostory, neonRepository } = useContextDependency();

  const [, , background] = useRetrieveFromDomainToContext(
    () =>
      backgroundImageRepostory.getBackgroundImageById(
        appearence.backgroundImageId?.id as number,
      ),
    undefined,
    !!appearence.backgroundImageId,
    setBackgroundImageById,
    (state: RootState) =>
      selectBackgroundImageById(state, appearence.backgroundImageId?.id as number),
  );

  const [, , neon] = useRetrieveFromDomainToContext(
    () => neonRepository.getNeonById(appearence.neon?.id as number),
    undefined,
    !!appearence.neon,
    setNeonById,
    (state: RootState) => selectNeonById(state, appearence.neon?.id as number),
  );

  return {
    ...profil,
    bannerImage: appearence.fileNameUploaded || profil.bannerImage,
    backgroundImage: background,
    neon: neon as Neon,
  };
};

export const getInitialValuesProfilStructureDetails = (
  t: UseTranslateReturn,
  profil?: StructureProfil,
) => {
  if (!profil) {
    return {
      name: '',
      description: '',
      talkLangs: [],
      platforms: [],
      gamingProfil: '',
    };
  }
  return {
    name: profil.name,
    description: profil.description,
    talkLangs: profil.talkLangs?.map((lang) => lang.id.toString()),
    platforms: profil.platforms?.map((platform) => platform.id.toString()),
    gamingProfil: profil.gamingProfil,
  };
};

export const getInitialValuesStructureProfilNetwork = (profil?: StructureProfil) => {
  if (!profil)
    return {
      discord: '',
      twitch: '',
      youtube: '',
      twitter: '',
      instagram: '',
      facebook: '',
      ping: false,
      video: '',
      privacies: {},
    };
  else {
    return {
      discord: profil.discord,
      twitch: profil.twitch,
      youtube: profil.youtube,
      twitter: profil.twitter,
      instagram: profil.instagram,
      facebook: profil.facebook,
      video: profil.video,
      ping: profil.ping,
      privacies: {
        ...profil.privacies?.reduce((acc, privacy) => {
          return {
            ...acc,
            [privacy.code]: {
              profilId: privacy.profil.id,
              code: privacy.code,
              isChecked: privacy.isChecked,
            },
          };
        }, {}),
      },
    };
  }
};
