import { Box } from '@mui/material';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type WrapperModalActionProps = {
  closeModal: () => void;
  loading?: boolean;
};

export const WrapperModalAction: FC<WrapperModalActionProps> = ({
  closeModal,
  loading,
  children,
}) => {
  const { authRepository } = useContextDependency();
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  if (!isAccountLoaded || loading) return <LoadingScreen loading />;

  if (isAccountLoaded && !authRepository.currentUser) {
    closeModal();
  }

  return <Box className={'modal-box'}>{children}</Box>;
};
