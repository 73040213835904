import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useTranslate } from 'primary/hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import {
  setConnectedUser,
  setConnectedUserProfil,
} from '../../../domain/user/store/UserSlice';
import { RootState } from '../../../store';
import { useContextDependency } from '../../hooks/useContextDependency';

export const useUserParameters = () => {
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const dispatch = useDispatch();
  const { userProfilRepository, authRepository } = useContextDependency();
  const t = useTranslate();

  const retry = () => {
    return Promise.all([
      authRepository
        .refreshCurrentUser()
        .then((value) => dispatch(setConnectedUser(value))),
      userProfilRepository
        .getConnectedUserProfil()
        .then((value) => dispatch(setConnectedUserProfil(value))),
    ]);
  };
  const onSubmitted = () => {
    sendEventToastMessage(t('general.edit.success'), 'success');
    return retry();
  };

  return {
    onSubmitted,
    user,
    userProfilRepository,
    retry,
  };
};
