import '../ParametersPage.scss';

import classNames from 'classnames';
import { getNeonBorderClassName } from 'domain/profil/Profil.func';
import { OtherStructureProfilBannerOverview } from 'primary/Components/User/OtherStructureProfilBannerOverview';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { InvalidAccessPage } from 'primary/pages/InvalidAccess/InvalidAccessPage';
import React, { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { LoadingScreen } from '../../../Components/LoadingScreen/LoadingScreen';
import { MenusParameters } from '../../../Components/MenuParameters/MenusParameters';
import { menusParametersStructure } from '../../../Components/MenuParameters/MenusParametersStructure.config';
import { useTranslate } from '../../../hooks/useTranslate';
import { useStructureParameters } from '../../../Parameters/user/useUserStructureParameters.hook';

export const StructureParametersPage: FC = () => {
  const t = useTranslate();
  const { structureMemberRepository, authRepository } = useContextDependency();
  const { structure, isInitialStructureFetching, isAccountLoaded } =
    useStructureParameters();
  const { idStructure } = useParams<{ idStructure: string }>();
  const neonBorderClassName = getNeonBorderClassName(structure?.structureProfil);
  const navigate = useNavigate();
  const [adminStructure, , fetchingAdmin] = useRetrieveFromDomain(
    //@ts-ignore
    () => structureMemberRepository.getStructureAdmin(idStructure),
    undefined,
    !!idStructure,
  );
  const isAdmin = adminStructure?.user.id === authRepository?.currentUser?.id;
  const isLoading = !isAccountLoaded || fetchingAdmin || isInitialStructureFetching;
  return (
    <div key={idStructure} className={classNames('page-parameters', '-structure')}>
      <LoadingScreen
        message={t('structure.parameters.getStructureMsgLoading')}
        loading={isLoading}
      />
      {!isLoading && !isAdmin && <InvalidAccessPage />}
      {!isLoading && structure?.structureProfil && isAdmin && (
        <>
          <MenusParameters
            confs={menusParametersStructure}
            header={
              <div
                className={'-paramHeader'}
                onClick={() =>
                  navigate(
                    routesConfig.otherStructureProfilDetails.structureProfilDetails.to(
                      structure.structureProfil?.id as number,
                    ),
                  )
                }
              >
                <OtherStructureProfilBannerOverview
                  idStructureProfil={structure.structureProfil.id}
                  className={classNames(neonBorderClassName)}
                  banner={structure.structureProfil.bannerImagePublicUrl}
                />
                <span>{t('general.menuStructParamTitle')}</span>
                <span className={'-accent'}>{structure.name}</span>
              </div>
            }
          />
          <div className={'parameters__content --scrollBarContent'}>
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};
