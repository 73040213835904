import { LoadingButton } from '@mui/lab';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { GameProfil } from 'domain/gameProfil/GameProfil';
import { IGameProfilRepository } from 'domain/gameProfil/GameProfil.repository';
import { ListGameSelectable } from 'primary/Components/UserStatsGameForms/ListGameSelectable';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

export type GamesFormListData = {
  games?: GAME_ACTIVE_LIST[];
};

export type GamesFormListDataSubmit = {
  games?: GAME_ACTIVE_LIST[];
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    games: yup.array().required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    gameProfilRepository: IGameProfilRepository,
    onSubmitted: (gamesProfil: GameProfil[]) => void,
    idStructureProfil?: number,
  ) =>
  (data: GamesFormListDataSubmit) => {
    if (idStructureProfil)
      return gameProfilRepository
        .updateStructureGameProfil(data, idStructureProfil)
        .then(onSubmitted);
    return gameProfilRepository.updateUserGameProfil(data).then(onSubmitted);
  };

interface GamesFormListProps {
  onSubmitted: (gamesProfil: GameProfil[]) => void;
  initialValues: GamesFormListData;
  idStructureProfil?: number;
}

export const GamesFormList: FC<GamesFormListProps> = ({
  idStructureProfil,
  onSubmitted,
  initialValues,
}) => {
  const { gameProfilRepoitory } = useContextDependency();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
  } = useForm<GamesFormListData>({
    resolver,
    // @ts-ignore
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  const [gamesSelectedState, setGamesSelectedState] = React.useState<GAME_ACTIVE_LIST[]>(
    initialValues.games || [],
  );
  const onSubmit = useOnSubmit(gameProfilRepoitory, onSubmitted, idStructureProfil);
  const t = useTranslate();
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'list-game-form'}>
      <ListGameSelectable
        games={Object.values(GAME_ACTIVE_LIST)}
        gamesSelected={gamesSelectedState}
        action={(gameToSelect) => {
          if (gamesSelectedState?.find((value) => value === gameToSelect)) {
            setGamesSelectedState(
              gamesSelectedState?.filter((value) => value !== gameToSelect),
            );
            setValue(
              'games',
              gamesSelectedState?.filter((value) => value !== gameToSelect),
            );
          } else {
            setGamesSelectedState([
              ...(gamesSelectedState || []),
              GAME_ACTIVE_LIST[gameToSelect],
            ]);
            setValue('games', [
              ...(gamesSelectedState || []),
              GAME_ACTIVE_LIST[gameToSelect],
            ]);
          }
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          variant={'contained'}
          color={'primary'}
          type={'submit'}
          loading={isSubmitting}
        >
          {t('general.edit.btnLabel')}
        </LoadingButton>
      </div>
    </form>
  );
};
