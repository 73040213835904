import './ActionModalAddMember.scss';

import { Add, ContentCopy } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, TextField } from '@mui/material';
import classNames from 'classnames';
import { Environment } from 'domain/environment/Environment';
import { EnvironmentRepository } from 'domain/environment/Environment.repository';
import { PermissionStructureEnum } from 'domain/permissionStructure/PermissionStructureEnum';
import { Structure } from 'domain/structure/Structure';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SystemEnvironmentRepository } from 'secondary/environment/SystemEnvironment.repository';

interface ActionModalAddMemberProps {
  className?: string;
  structure: Structure;
}

export const ActionModalAddMember = ({
  className,
  structure,
}: ActionModalAddMemberProps) => {
  const { structureMemberRepository, authRepository } = useContextDependency();
  const [isModalOpen, , openModal, closeModal] = useBooleanToggle();
  const t = useTranslate();
  const navigate = useNavigate();

  const canInvite = structure.members
    .find((m) => m.user.id === authRepository.currentUser?.id)
    ?.role.permissions.find((p) => p.code === PermissionStructureEnum.INVITE_MEMBERS);
  const [link, , fetching] = useRetrieveFromDomain(
    () => structureMemberRepository.getOrCreateLinkInvitationMember(structure.id),
    undefined,
    !!canInvite,
  );
  const environmentRepository: EnvironmentRepository = new SystemEnvironmentRepository();
  const environment: Environment = environmentRepository.get();

  const handleGoMercato = () => {
    closeModal();
    navigate(routesConfig.mercatoSubPath.mercatoPlayer.to());
  };

  const handleCopyLink = () => {
    if (!link) return;
    const linkUrl = `${environment.forntUrl}${routesConfig.homeWithInvitationLink.to(
      link.uuid,
    )}`;
    navigator.clipboard.writeText(linkUrl).then(() => {
      sendEventToastMessage('Copié avec succès', 'success');
    });
  };
  return (
    <div className={classNames('actionModalAddMember', className)}>
      <Button
        onClick={openModal}
        className={'actionModalAddMember__btn'}
        variant="contained"
        color={'primary'}
      >
        <Add />
        {t('structure.members.datatable.addMember')}
      </Button>
      <ModalContainer
        handleClose={closeModal}
        isOpen={isModalOpen}
        header={{
          title: t('structure.members.datatable.titleModalAddMember'),
          withButtonClose: true,
        }}
        content={
          <Box className={'modal-add-member'}>
            <div className={'choiceModaliteAddMember'}>
              <div className={'choiceModaliteAddMember__choices'}>
                <div className={'-addMercatoPlayer'}>
                  <p>{t('structure.members.datatable.addMercatoPlayerNotice')}</p>
                  <Button
                    onClick={handleGoMercato}
                    className={'-choiceModaliteAddMember__btn'}
                    variant="contained"
                    color={'primary'}
                  >
                    <Add />
                    {t('structure.members.datatable.addMercatoPlayer')}
                  </Button>
                </div>
                <Divider orientation={'horizontal'} flexItem className={'-divider'} />
                <div className={'-inviteNewPlayer'}>
                  <p>{t('structure.members.datatable.inviteMemberNotice')}</p>
                  <div className={'-fieldDisabledWithCopy'}>
                    <InputWrapper
                      errors={{}}
                      isSubmitting={false}
                      Input={TextField}
                      inputProps={{
                        // @ts-ignore
                        disabled: true,
                        variant: 'filled',
                        value: `${
                          environment.forntUrl
                        }${routesConfig.homeWithInvitationLink.to(link?.uuid)}`,
                      }}
                    />
                    <IconButton onClick={handleCopyLink} disabled={fetching}>
                      <ContentCopy />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        }
      />
    </div>
  );
};
