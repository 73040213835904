import './RelationPage.scss';

import { Relation, RelationFilters } from 'domain/relation/Relation';
import { RelationTypeEnum } from 'domain/relation/RelationTypeEnum';
import { InfiniteScroll } from 'primary/Components/InfiniteScroll/InfiniteScroll';
import { LeftRight } from 'primary/Components/Layout/LeftRight/LeftRight';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import { NoContentPreviewProfil } from 'primary/pages/myStructures/MyStructures.page';
import { WrapperFetchUserProfilCard } from 'primary/pages/profil/OtherUserProfilPage';
import { PreviewRelationUser } from 'primary/relation/PreviewRelationUser';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const RelationsPage: FC = () => {
  const [selectedFriend, setSelectedFriend] = useState<Relation | null>(null);
  const { authRepository, relationRepository } = useContextDependency();
  const t = useTranslate();
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const { fetchedResource, setFilters, nextPage, loading } = useFetchWithFilterPage<
    Relation,
    RelationFilters
  >({
    keyStoreFilters: 'relationsList',
    filters: {},
    staticFilters: {
      user: user?.id,
      type: RelationTypeEnum.FRIEND,
    },
    callApi: (filters, page) => relationRepository.searchFriendsOfUser(filters, page, 15),
    conditionFetch: (f) => !!user,
  });
  const selectedFriendToDisplay =
    selectedFriend?.invited.id === authRepository.currentUser?.userProfil.id
      ? selectedFriend?.owner
      : selectedFriend?.invited;
  if (!authRepository.currentUser) return null;
  return (
    <div className={'myRelationsPage'}>
      <LeftRight
        sizeLeft={'big'}
        close={() => setSelectedFriend(null)}
        transparency={'opaq'}
        isOpen={!!selectedFriend}
        scrollInside
        left={
          <div className={'myRelationsPage__listRelations'}>
            <h3>{t('relations.myFriendsTitle')}</h3>
            <InfiniteScroll onScrollBottom={() => nextPage()} isLoading={loading}>
              {(scrollToTop) => (
                <ListElements
                  loading={loading}
                  empty={fetchedResource?.content.length === 0}
                >
                  {!loading &&
                    fetchedResource &&
                    fetchedResource.content.map((relation) => (
                      <PreviewRelationUser
                        key={relation.id}
                        onClick={(relation) => setSelectedFriend(relation)}
                        isSelected={selectedFriend?.id === relation.id}
                        relation={relation}
                      />
                    ))}
                </ListElements>
              )}
            </InfiniteScroll>
          </div>
        }
        right={
          <>
            {selectedFriendToDisplay?.idProfil && (
              <div
                className={'-containerProfil --scrollBarContent'}
                key={selectedFriendToDisplay?.idProfil}
              >
                <WrapperFetchUserProfilCard
                  id={selectedFriendToDisplay?.idProfil}
                  key={selectedFriendToDisplay?.idProfil}
                />
              </div>
            )}
            {!selectedFriendToDisplay?.idProfil && (
              <NoContentPreviewProfil type={'userProfil'} />
            )}
          </>
        }
      />
    </div>
  );
};

//
// export const RelationsPage: FC = () => {
//   const { authRepository, relationRepository } = useContextDependency();
//   const t = useTranslate();
//   const user = useSelector((state: RootState) => state.user.connectedUser);
//
//   const { fetchedResource, goToPage, loading } = useFetchWithFilterPage<
//     Relation,
//     RelationFilters
//   >({
//     filters: { user: user?.id },
//     callApi: (filters, page) =>
//       relationRepository.searchFriendsOfUser(filters.user as number, page, 32),
//   });
//
//   if (!authRepository.currentUser) return null;
//
//   return (
//     <div className={'relationPage'}>
//       <LayoutPageList title={t('relation.page.list.title')} background={Background}>
//         <div className={'relationPage__listContainer'}>
//           <div className={'relationPage__listContainer-header'}>
//             <Pagination
//               key={fetchedResource?.currentPage}
//               size={'medium'}
//               count={fetchedResource?.pagesCount}
//               page={
//                 fetchedResource?.currentPage
//                   ? fetchedResource?.currentPage + 1
//                   : undefined
//               }
//               onChange={(e, value) => goToPage(value - 1)}
//             />
//             {loading && <CircularProgress />}
//           </div>
//           <div className={'relationPage__content'}>
//             {fetchedResource?.content.map((relation) => {
//               return <PreviewRelationUser relation={relation} />;
//             })}
//             {!fetchedResource?.content ||
//               (fetchedResource.content && fetchedResource.content.length == 0 && (
//                 <div className={'myMessagePage__conversations-noContent'}>
//                   <span>{t('message.conversation.noContent')}</span>
//                 </div>
//               ))}
//           </div>
//         </div>
//       </LayoutPageList>
//     </div>
//   );
// };
