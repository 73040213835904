import { AxiosInstance } from 'axios';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { Pageable } from 'domain/pageable/Pageable';
import { JoinStructureFormData } from 'primary/Components/Profils/User/ActionButton/Forms/JoinStructureForm';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toInvitationLink } from 'secondary/invitationLink/RestInvitationLink';
import { toPageable } from 'secondary/RestPageable';

import {
  StructureMember,
  StructureMemberFilters,
} from '../../domain/structureMember/StructureMember';
import { IStructureMemberRepository } from '../../domain/structureMember/StructureMember.repository';
import { RestStructureMember, toStructureMember } from './RestStructureMember';

export class StructureMemberRepository
  extends AxiosProviderRepository
  implements IStructureMemberRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  search(
    filters: StructureMemberFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<StructureMember>> {
    return this.axios
      .get('/unpy/api/structure/members/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toStructureMember));
  }

  getOrCreateLinkInvitationMember(idStructure: number): Promise<InvitationLink> {
    return this.axios
      .get(`/unpy/api/structure/${idStructure}/member/link/invitation`)
      .then((resp) => toInvitationLink(resp.data));
  }

  getPendingStructureInvitations(): Promise<StructureMember[]> {
    return this.axios
      .get(`/unpy/api/structure/members/invitation`)
      .then((resp) =>
        resp.data.map((member: RestStructureMember) => toStructureMember(member)),
      );
  }

  responseMemberInvitation(
    response: boolean,
    userId: number,
    structureId: number,
  ): Promise<void> {
    return this.axios.post(`/unpy/api/structure/members/invitation`, {
      user: userId,
      structure: structureId,
      accept: response,
    });
  }

  getMember(idStructure: number, idMember: number): Promise<StructureMember> {
    return this.axios
      .get(`/unpy/api/structure/${idStructure}/member/${idMember}`)
      .then((resp) => toStructureMember(resp.data));
  }

  getMembers(idStructure: number): Promise<StructureMember[]> {
    return this.axios.get(`/unpy/api/structure/${idStructure}/members`).then((resp) => {
      return resp.data.map((member: RestStructureMember) => toStructureMember(member));
    });
  }
  updateMember(data: any): Promise<StructureMember> {
    return this.axios
      .put('/unpy/api/structure/members', data)
      .then((resp) => toStructureMember(resp.data));
  }

  createMember(data: any): Promise<StructureMember> {
    return this.axios
      .post('/unpy/api/structure/members', data)
      .then((resp) => toStructureMember(resp.data));
  }

  createMemberFromLink(uuid: string): Promise<StructureMember> {
    return this.axios
      .post(`/unpy/api/structure/member/link/invitation/consumed/${uuid}`)
      .then((resp) => toStructureMember(resp.data));
  }

  joinStructure(data: JoinStructureFormData): Promise<void> {
    return this.axios.post('/unpy/api/structure/join', data);
  }

  getStructureAdmin(structureId: number): Promise<StructureMember> {
    return this.axios
      .get(`/unpy/api/structure/admin/${structureId}`)
      .then((resp) => toStructureMember(resp.data));
  }

  deleteMember(userId: number, structureId: number): Promise<void> {
    return this.axios.delete(
      `/unpy/api/structure/members/delete/${userId}/${structureId}`,
    );
  }
}
