import { Block } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { setModalConfirmClose, setModalConfirmOpen } from 'domain/modal/store/ModalSlice';
import { UserProfil } from 'domain/profil/UserProfil';
import { setRelation } from 'domain/relation/store/RelationSlice';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch } from 'react-redux';

interface ActionButtonUnBlockProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const ActionButtonUnBlock = ({ profil, withIcon }: ActionButtonUnBlockProps) => {
  const t = useTranslate();
  const { relationRepository, authRepository } = useContextDependency();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setModalConfirmClose());
  const handleUnBlock = () => {
    if (!profil.userId) return;
    setIsLoading(true);
    relationRepository
      .unBlockUser(profil.userId)
      .then(() => {
        sendEventToastMessage(
          t(`relations.unBlock.success`, { name: profil.idPlayer }),
          'success',
        );
        relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
          dispatch(setRelation(relations));
        });
      })
      .finally(() => {
        setIsLoading(false);
        handleClose();
      });
  };

  const openModalConfirmUnBlock = () => {
    dispatch(
      setModalConfirmOpen({
        props: {
          title: t('relations.unBlock.title', { name: profil.idPlayer }),
          labelDecline: t(`relations.unBlock.cancelBtnText`),
          labelAccept: t(`relations.unBlock.confirmBtnText`),
          action: handleUnBlock,
          description: t(`relations.unBlock.notice`),
        },
      }),
    );
  };

  return (
    <LoadingButton
      loading={isLoading}
      color={'inherit'}
      variant={'text'}
      className={'-actionButton'}
      disabled={!authRepository.currentUser}
      onClick={stopPropagationBeforeAction(openModalConfirmUnBlock)}
    >
      {withIcon && <Block />}
      {t(`profil.userProfilAction.unBlock`)}
      {!authRepository.currentUser && (
        <span className={'-notice'}>Vous devez être connecté</span>
      )}
    </LoadingButton>
  );
};
