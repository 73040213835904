import './UnpyTabs.scss';

import { Box, Divider, Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { To, useLocation, useNavigate } from 'react-router-dom';

type TabConf = {
  label: string;
  component?: ReactNode;
  action?: () => void;
  to?: To;
};

interface UnpyTabsProps {
  tabsConf: TabConf[];
  center?: boolean;
  black?: boolean;
  fonced?: boolean;
  asTag?: boolean;
  marged?: boolean;
  withDivider?: boolean;
  asFlex?: boolean;
  background?: 'mercato' | 'event';
  asTitle?: boolean;
  asTitleBig?: boolean;
  fullHeight?: boolean;
  portalElement?: HTMLElement | null;
  onChange?: (index: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const UnpyTabs = ({
  tabsConf,
  center,
  black,
  fonced,
  marged,
  withDivider,
  asFlex,
  fullHeight,
  asTitle,
  asTitleBig,
  portalElement,
  onChange,
  background,
  asTag,
}: UnpyTabsProps) => {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const index = tabsConf.findIndex((conf) => {
      const to =
        typeof conf.to === 'function'
          ? // @ts-ignore
            conf.to?.()
          : conf.to;
      return to === location.pathname;
    });
    if (index !== -1) {
      setValue(index);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (tabsConf[newValue]?.to !== undefined) {
      if (typeof tabsConf[newValue].to === 'string') {
        navigate(tabsConf?.[newValue]?.to as To);
      } else if (typeof tabsConf[newValue].to === 'function') {
        // @ts-ignore
        navigate(tabsConf[newValue].to());
      }
    } else {
      setValue(newValue);
    }
    onChange?.(newValue);
  };

  return (
    <Box
      sx={{ width: '100%' }}
      className={classNames(
        'unpy-tabs',
        '--scrollBarContent',
        { '-fonced': fonced },
        { '-center': center },
        { '-marged': marged },
        { '-asFlex': asFlex },
        { '-asTitle': asTitle },
        { [`-background--${background}`]: !!background },
        { '-asTitleBig': asTitleBig },
        { '-asTag': !!asTag },
        { '-fullHeight': fullHeight },
        { '-black': black },
      )}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered={center}
          className={'unpy-tabs__tabs'}
          variant={center ? 'fullWidth' : 'scrollable'}
        >
          {tabsConf.map((conf, index) => (
            <Tab
              key={conf.label}
              onClick={(e) => {
                if (conf.action) {
                  conf.action();
                }
                return e;
              }}
              label={conf.label}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </Box>
      {withDivider && (
        <div className={'unpy-tabs__divider'}>
          <Divider className={'-divider'} />
        </div>
      )}
      {tabsConf.map((conf, index) => (
        <TabPanel key={value + index} value={value} index={index}>
          {!portalElement && value === index && conf?.component}
          {portalElement &&
            value === index &&
            createPortal(conf?.component, portalElement, `portal-${index}`)}
        </TabPanel>
      ))}
    </Box>
  );
};
