import { Box } from '@mui/material';
import { Annonce } from 'domain/annonce/Annonce';
import { AnnonceTypeEnum } from 'domain/annonce/AnnonceTypeEnum';
import { CreateEditAnnonceForm } from 'primary/annonce/Form/CreateEditAnnonceForm';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
interface ModalCreateEditAnnonceProps {
  isOpen: boolean;
  handleClose: () => void;
  onSubmitted?: (annonce: Annonce) => void;
  eventId?: string;
  structureId?: string;
  annonceToEdit?: Annonce;
  type: AnnonceTypeEnum;
}
export const ModalCreateEditAnnonce = ({
  isOpen,
  handleClose,
  onSubmitted,
  eventId,
  structureId,
  annonceToEdit,
  type,
}: ModalCreateEditAnnonceProps) => {
  const t = useTranslate();
  const enhancedOnSubmitted = (annonce: Annonce) => {
    handleClose();
    onSubmitted?.(annonce);
  };

  if (!structureId && !eventId) return null;

  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      header={{
        title: annonceToEdit
          ? t('modals.annonce.editTitle')
          : t('modals.annonce.createTitle'),
      }}
      content={
        <Box className={'modal-box'}>
          <CreateEditAnnonceForm
            onSubmitted={enhancedOnSubmitted}
            eventId={eventId}
            structureId={structureId}
            annonceToEdit={annonceToEdit}
            type={type}
            onCancel={() => handleClose()}
          />
        </Box>
      }
    />
  );
};
