import './ActionButton.scss';

import { PersonAdd } from '@mui/icons-material';
import { Button } from '@mui/material';
import { setAddFriendModalOpen } from 'domain/modal/store/ModalSlice';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { isPrivacyRestricted } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import { selectConnectedUserBlocked } from 'domain/relation/store/RelationSlice';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRedirectUrlForAction } from 'primary/hooks/useOpenModalActionFromLogin';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ActionButtonAddFriendProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const ActionButtonAddFriend = ({
  profil,
  withIcon,
}: ActionButtonAddFriendProps) => {
  const { relationRepository, authRepository } = useContextDependency();
  const [isOpen, toggleOpen, , closeModal] = useBooleanToggle();
  const t = useTranslate();
  const dispatch = useDispatch();
  const urlRedirect = useRedirectUrlForAction(
    'addFriend',
    profil.id.toString(),
    profil.idPlayer,
  );

  const openModalAddFriend = () => {
    if (authRepository?.currentUser) {
      dispatch(
        setAddFriendModalOpen({ props: { userId: profil.id, name: profil.idPlayer } }),
      );
    } else {
      if (urlRedirect) {
        authRepository?.login(urlRedirect.toString());
      }
    }
  };
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.idProfil === profil.id);
  const isRestricted =
    !!alreadyBlocked || isPrivacyRestricted(profil, PRIVACY_CODE.ADD_FRIEND);
  return (
    <Button
      color={'inherit'}
      variant={'text'}
      className={'-actionButton'}
      onClick={stopPropagationBeforeAction(openModalAddFriend)}
      disabled={isRestricted}
    >
      {withIcon && <PersonAdd />}
      {t('profil.userProfilAction.addFriend', { name: profil.idPlayer })}
    </Button>
  );
};
