import '../../structure/Profil/Appearence.scss';

import { ThemeProvider } from '@mui/material';
import { GamesFormList } from 'primary/Components/UserStatsGameForms/GamesFormList';
import { useStructureParameters } from 'primary/Parameters/user/useUserStructureParameters.hook';
import React, { useState } from 'react';

import { GAME_ACTIVE_LIST } from '../../../../domain/game/GameActiveList';
import { PageLayout } from '../../../Components/PageLayout/PageLayout';
import { Title } from '../../../Components/Title/Title';
import { useTranslate } from '../../../hooks/useTranslate';
import { themeParameters } from '../../parameters.helper';

export const UnpyStructureProfilStatsParameters = () => {
  const t = useTranslate();
  const [gameSelected, setGameSelected] = useState<GAME_ACTIVE_LIST[] | undefined>(
    undefined,
  );
  const { structure, onSubmitted } = useStructureParameters();

  return (
    <div className={'appearence'}>
      <PageLayout>
        <>
          <Title
            title={t('user.parameters.interests.title')}
            level={1}
            compensatePadding
            threeQuarter
            bordered
          />
          <ThemeProvider theme={themeParameters}>
            <div className={'-gameFormContainer'}>
              <h2>{t('parameters.menus.profil.user.form.listGameSelectedTitle')}</h2>
              <GamesFormList
                idStructureProfil={structure?.structureProfil?.id}
                onSubmitted={onSubmitted}
                initialValues={{
                  games: structure?.structureProfil?.games?.map((game) => game.game.code),
                }}
              />
            </div>
          </ThemeProvider>
        </>
      </PageLayout>
    </div>
  );
};
