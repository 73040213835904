import captEditMatchs from '@assets/captures/captEditMatchs.png';
import captEditScore from '@assets/captures/captEditScore.png';
import captHandleActivities from '@assets/captures/captHandleActivities.png';
import captHandleStructures from '@assets/captures/captHandleStructures.png';
import captHandlingEvents from '@assets/captures/captHandlingEvents.png';
import captPerfs from '@assets/captures/captPerfs.png';
import captPersonnalizeProfil from '@assets/captures/captPersonnalizeProfil.png';
import captProfilEsport from '@assets/captures/captProfilEsport.png';
import BandeBleu from '@assets/Home/bandes/bande_bleu.png';
import BandeViolette from '@assets/Home/bandes/bande_violette.png';
import BandeViolette2 from '@assets/Home/bandes/bande_violette_2.png';
import {
  Domain,
  Edit,
  EmojiEvents,
  Gamepad,
  GroupAdd,
  ManageAccounts,
  PriceCheck,
} from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Hidden, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
  selectAllBackgroundImages,
  setBackgroundImages,
} from 'domain/backgroundImage/store/BackgroundImageSlice';
import { GAME_ACTIVE_LIST, GAME_IMAGES } from 'domain/game/GameActiveList';
import { selectAllNeons, setNeons } from 'domain/neon/store/NeonSlice';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomainToContext } from 'primary/hooks/useRetrieveFromDomain';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { RootState } from 'store';

import { Section } from '../../Components/Section/Section';
import { useTranslate } from '../../hooks/useTranslate';
import { HomeSectionProps } from '../Home';

const responsiveConf = [
  {
    breakpoint: 1024,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
];

export const HomeSlidersSection = ({ className }: HomeSectionProps) => {
  const navigate = useNavigate();
  const { backgroundImageRepostory, neonRepository, allProfilRepository } =
    useContextDependency();
  const theme = useTheme();
  const smallerThanMid = useMediaQuery(theme.breakpoints.down('lg'));
  const [, , neons] = useRetrieveFromDomainToContext(
    () => neonRepository.getAllNeons(),
    undefined,
    true,
    setNeons,
    selectAllNeons,
  );
  const t = useTranslate();

  const [, , backgrounds] = useRetrieveFromDomainToContext(
    () => backgroundImageRepostory.getAllBackgroundImages(),
    undefined,
    true,
    setBackgroundImages,
    (state: RootState) => selectAllBackgroundImages(state),
  );
  return (
    <Section className={className}>
      <div className={'-home-slider-event-with-mercato'}>
        <div className={'-diagonal-effect'}></div>
        <div className={'-content'}>
          <h1 className={'-title'}>{t('home.mercatoWithEvent.title')}</h1>
          <div className={'-row -wrapped -stretch'}>
            <div className={'-mercato'}>
              <div className={'-linkMercatoHome'}>
                <Link to={routesConfig.mercato.to()}>
                  <div className={'-top--title'}>
                    <img
                      alt={'bande-violette'}
                      src={BandeViolette}
                      className={'-img-bande -violette'}
                    />
                    <img
                      alt={'bande-violette-variant'}
                      src={BandeViolette2}
                      className={'-img-bande -violette2'}
                    />
                  </div>
                  <div className={'-title'}>
                    <h2>{t('home.mercato.title')}</h2>
                    <span>{t('home.mercato.subtitle')}</span>
                  </div>
                  <div className={'-bottom--title'}>
                    <img
                      alt={'bande-bleu'}
                      src={BandeBleu}
                      className={'-img-bande -bleu'}
                    />
                  </div>
                </Link>
              </div>
              <div className={'-row -timeline'}>
                <Timeline
                  className={'timelineHome'}
                  position={smallerThanMid ? 'left' : 'alternate'}
                >
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden xlDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captPersonnalizeProfil}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>
                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <Edit className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={'-timelineContentContainer'}>
                      <h2>Personnalisez votre profil e-sport</h2>
                      <p>
                        Profitez de <strong className={'-accent'}>néons </strong> et
                        <strong className={'-accent'}> fonds</strong> exceptionels pour
                        personnaliser votre profil. Renseignez les jeux auxquel vous
                        jouez, les détails de votre profil. Et ajoutez une
                        <strong className={'-accent'}> vidéo promotionnelle</strong>
                      </p>
                      <Hidden xlUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captPersonnalizeProfil}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden xlDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captProfilEsport}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <GroupAdd className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={'-timelineContentContainer'}>
                      <h2>Profitez d'une page dédiée à votre profil e-sport</h2>
                      <p>
                        Rendez votre
                        <strong className={'-accent'}>
                          {' '}
                          profil e-sport accessible à tous
                        </strong>{' '}
                        depuis n'importe ou.
                        <strong className={'-accent'}>
                          {' '}
                          Partagez le sur les réseaux
                        </strong>
                        , augmentez votre visibilité dans l'e-sport
                      </p>
                      <Hidden xlUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captProfilEsport}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden xlDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandleActivities}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <Gamepad className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={'-timelineContentContainer'}>
                      <h2>Gérez toutes vos activités e-sports depuis Unpy</h2>
                      <p>
                        Utilisez le Dashboard Mes activités pour gérer vos demandes en
                        attentes, vos
                        <strong className={'-accent'}> prochains matchs</strong> et vos
                        <strong className={'-accent'}> derniers résultats</strong>
                      </p>
                      <Hidden xlUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandleActivities}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden xlDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandleStructures}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <Domain className={'-icon'} />
                      </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent className={'-timelineContentContainer'}>
                      <h2>Créez et gérez votre propre structure</h2>
                      <p>
                        Rejoignez ou créez une
                        <strong className={'-accent'}>
                          {' '}
                          structure e-sport amateur ou professionnel
                        </strong>{' '}
                        pour agrandir votre réseau,
                        <strong className={'-accent'}>
                          {' '}
                          organiser vos compétitions
                        </strong>{' '}
                        et assurer le future de votre
                        <strong className={'-accent'}> carrière e-sport</strong>
                      </p>

                      <Hidden xlUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandleStructures}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </div>
            </div>

            <div className={'-events'}>
              <div className={'-wrapperContent -linkEvent'}>
                <Link
                  to={routesConfig.events.to()}
                  className={classNames('-title', '-linkWithoutDecoration')}
                >
                  <h2>{t('home.events.title')}</h2>
                  <span>{t('home.events.subtitle')}</span>
                </Link>
                <Slider
                  speed={500}
                  slidesToScroll={2}
                  rows={1}
                  slidesPerRow={1}
                  slidesToShow={4}
                  swipeToSlide
                  arrows
                  infinite
                  centerMode
                  responsive={responsiveConf}
                  autoplay
                >
                  {Object.values(GAME_ACTIVE_LIST).map((key: GAME_ACTIVE_LIST) => (
                    <Link
                      to={routesConfig.eventListWithGameFilter.to(key)}
                      className={classNames(
                        'gameSelectionCaroussel__item',
                        '-linkWithoutDecoration',
                      )}
                      key={key}
                    >
                      <div className={'-paddingWrapperSliderHome'}>
                        <img
                          src={GAME_IMAGES[key as GAME_ACTIVE_LIST] as string}
                          alt={key}
                        />
                      </div>
                    </Link>
                  ))}
                </Slider>
              </div>
              <div className={'-row -timeline'}>
                <Timeline
                  className={'timelineHome'}
                  position={smallerThanMid ? 'right' : 'alternate-reverse'}
                >
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden xlDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandlingEvents}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <ManageAccounts className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={'-timelineContentContainer'}>
                      <h2>Gérez vos propres événements</h2>
                      <p>
                        Organisez et gérez vos propres événements simplement avec Unpy et
                        sa gestion des <strong className={'-accent'}>inscriptions</strong>
                        , des <strong className={'-accent'}> matchs </strong> et des
                        <strong className={'-accent'}> scores </strong>.
                      </p>
                      <Hidden xlUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandlingEvents}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden xlDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captPerfs}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <EmojiEvents className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector></TimelineConnector>
                    </TimelineSeparator>

                    <TimelineContent className={'-timelineContentContainer'}>
                      <h2>
                        Accumulez des résultats et étoffez les statistiques de votre
                        profil e-sport
                      </h2>
                      <p>
                        Profitez de la <strong className={'-accent'}>visibilité</strong>{' '}
                        que peut vous donner votre page de profil e-sport et son{' '}
                        <strong className={'-accent'}> onglet performance</strong>.
                      </p>
                      <Hidden xlUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captPerfs}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden xlDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captEditScore}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <PriceCheck className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={'-timelineContentContainer'}>
                      <h2>Profitez d'un système de points automatique ou personnalisé</h2>
                      <p>
                        Choisissez entre une{' '}
                        <strong className={'-accent'}>
                          gestion des scores automatique
                        </strong>{' '}
                        avec des valeurs préféinies ou optez pour une{' '}
                        <strong className={'-accent'}>solution personnalisée</strong> en
                        saisisant les scores directement
                      </p>
                      <Hidden xlUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captEditScore}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden xlDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captEditMatchs}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <Edit className={'-icon'} />
                      </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent className={'-timelineContentContainer'}>
                      <h2>
                        Unpy génére les matchs, vous n'avez plus qu'a rentrer les scores !
                      </h2>
                      <p>
                        Unpy s'occupe de{' '}
                        <strong className={'-accent'}>générer les matchs</strong> au
                        démarrage du tournois avec un{' '}
                        <strong className={'-accent'}>placement aléatoire</strong>.
                        Saisissez les <strong className={'-accent'}> scores </strong>, le
                        classement se mettra à jours
                        <strong className={'-accent'}> automatiquement</strong>.
                      </p>
                      <Hidden xlUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captEditMatchs}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
