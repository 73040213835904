import './ModalFilters.scss';

import { Button } from '@mui/material';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { ReactNode } from 'react';

type ModalFiltersProps = {
  filtersComp: ReactNode;
  title?: string;
  resetFilters: () => void;
};

export const ModalFitlers = ({ filtersComp, resetFilters, title }: ModalFiltersProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const t = useTranslate();
  return (
    <div className={'modalFilterCompContainer'}>
      <Button
        variant={'contained'}
        color={'primary'}
        className={'-openFilterModal'}
        onClick={() => setIsModalOpen(true)}
      >
        {t('event.filters.title')}
      </Button>
      <ModalContainer
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        header={{
          title: title || t('event.filters.title'),
          withButtonClose: true,
        }}
        content={
          <div className={'-modalFiltersContent'}>
            <div className={'-content'}>{filtersComp}</div>
            <div className={'-footer'}>
              <Button onClick={resetFilters} color={'primary'} variant={'outlined'}>
                {t(`layoutPageCommonList.filtersResetBtn`)}
              </Button>
              <Button
                variant={'outlined'}
                onClick={() => setIsModalOpen(false)}
                color={'primary'}
                type={'submit'}
              >
                {t('event.filters.validate')}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};
