import './ModalConfirm.scss';

import { Cancel, Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { ReactNode } from 'react';

import { useTranslate } from '../hooks/useTranslate';

export type ModalConfirmChoiceProps = {
  handleClose: () => void;
  title: string;
  description?: string | ReactNode;
  labelAccept?: string;
  labelDecline?: string;
  action: () => void;
  isOpen: boolean;
};

const ModalConfirm = ({
  handleClose,
  title,
  description,
  labelDecline,
  labelAccept,
  action,
  isOpen,
}: ModalConfirmChoiceProps) => {
  const t = useTranslate();
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      header={{ title, withButtonClose: true }}
      content={
        <Box className={'modal-confirm'}>
          <div className={'modal-confirm__container'}>
            {description && <span>{description}</span>}
            <div className={'-buttons'}>
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={() => handleClose()}
                className={'cancel'}
              >
                <Cancel />
                {labelDecline || t('modal.confirmModal.defaultDeclineLabel')}
              </Button>
              <LoadingButton
                variant={'contained'}
                color={'primary'}
                type={'submit'}
                onClick={() => action()}
              >
                <Check />
                {labelAccept || t('modal.confirmModal.defaultAcceptLabel')}
              </LoadingButton>
            </div>
          </div>
        </Box>
      }
    />
  );
};

export default ModalConfirm;
