import './BackgroundImageForm.scss';

import { CircularProgress } from '@mui/material';
import { BackgroundImageType } from 'domain/backgroundImage/BackgroundImageType';
import {
  selectAllBackgroundImages,
  setBackgroundImages,
} from 'domain/backgroundImage/store/BackgroundImageSlice';
import { isUserUp } from 'domain/user/User.helper';
import { get } from 'lodash';
import { routesConfig } from 'primary/Configs/Routes.config';
import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import React, { FC, useState } from 'react';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { RootState } from 'store';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomainToContext } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';
import { ImageSelectable } from '../ImageSelectable/ImageSelectable';

interface BackgroundImageFormProps {
  form: UseFormReturn<ProfilAppearenceFormData>;
  type: BackgroundImageType;
}

export const BackgroundImageForm: FC<BackgroundImageFormProps> = ({ form, type }) => {
  const { backgroundImageRepostory, authRepository } = useContextDependency();
  const t = useTranslate();
  const [selectedId, setSelectedId] = useState<number | undefined>(
    form.getValues('backgroundImageId')?.id,
  );
  const enhancedSetSelectedId = (id: number, isUp: boolean) => {
    setSelectedId(id);
    form.setValue('backgroundImageId', {
      id,
      isUp,
    });
  };
  const selectedError: FieldError | undefined = get(
    form.formState.errors,
    'backgroundImageId',
  ) as FieldError;

  const [, , backgrounds] = useRetrieveFromDomainToContext(
    () => backgroundImageRepostory.getAllBackgroundImages(),
    undefined,
    true,
    setBackgroundImages,
    (state: RootState) => selectAllBackgroundImages(state),
  );

  return (
    <div className={'background-image-form'}>
      <div className={'background-image-form__noUp'}>
        <h3>{t('parameters.menus.profil.user.form.backgroundImageLabel')} *</h3>
        {!backgrounds && <CircularProgress size={100} />}
        {backgrounds
          ?.filter((a) => a.type === type)
          ?.sort((a) => (a.isUp ? 1 : -1))
          .map((background) => (
            <ImageSelectable
              key={background.code}
              onClick={() => enhancedSetSelectedId(background.id, background.isUp)}
              alt={background.code}
              imageUrl={background.publicUrlSm}
              isSelected={!!selectedId && selectedId === background.id}
              isLockUp={background.isUp && !isUserUp(authRepository?.currentUser)}
            />
          ))}
        {selectedError && (
          <div className={'-errorUpContainer'}>
            <span className={'-error'}>{selectedError.message}</span>
            <Link to={routesConfig.up.to()} className={'-errorLink'}>
              page abonnement
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
