import './Mercato.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import { LayoutPageListFitlered } from 'primary/Components/Layout/Pages/LayoutPageListFiltered';
import { UnpyTabs } from 'primary/Components/Tabs/UnpyTabs';
import { EVENT_OPEN_CREATE_STRUCTURE } from 'primary/Components/User/LoggedInUserAvatarMenu';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { useSelectionGameListPage } from 'primary/Mercato/Mercato.helper';
import { StructureProfilList } from 'primary/ProfilList/StructureProfilList';
import { UserProfilList } from 'primary/ProfilList/UserProfilList';
import React, { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { RootState } from 'store';

import { Section } from '../Components/Section/Section';

export const MercatoPage: FC = () => {
  return (
    <Grid className={classNames('mercato')}>
      <Section className={'mercato__content'}>
        <div className={'-backgroundList'}></div>
        <Outlet />
      </Section>
    </Grid>
  );
};

const ArrowLeft = (props: any) => {
  return (
    <KeyboardArrowLeft
      {...props}
      className={classNames(props.className, '-arrow', '-left')}
    />
  );
};

const ArrowRight = (props: any) => {
  return (
    <KeyboardArrowRight
      {...props}
      className={classNames(props.className, '-arrow', '-right')}
    />
  );
};

export const MercatoPageRefonte: FC = () => {
  const t = useTranslate();
  const { authRepository } = useContextDependency();
  const { selectedGamesCode, handleSelection } =
    useSelectionGameListPage('mercatoPlayer');
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const refToSearch = useRef<HTMLDivElement | null>(null);
  const goToRef = () =>
    refToSearch?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const tabsConf = [
    {
      label: t(`mercatoTabs.players`),
      component: (
        <UserProfilList
          gamesSelected={selectedGamesCode}
          filters={
            teamCreating
              ? {
                  games: selectedGamesCode,
                  idStructure: teamCreating?.data?.structure,
                }
              : undefined
          }
        />
      ),
      to: routesConfig.mercatoSubPath.mercatoPlayer.to,
    },
    {
      label: t(`mercatoTabs.structures`),
      component: (
        <StructureProfilList
          keyDomainFilter={'mercatoStructure'}
          gamesSelected={selectedGamesCode}
        />
      ),
      to: routesConfig.mercatoSubPath.mercatoStructure.to,
    },
  ];

  return (
    <Grid className={classNames('mercato')}>
      <div className={'-backgroundFixed'}></div>
      <div className={'-headerMercato'}>
        <div className={'-titleContainer'}>
          <div className={'-titleWithButtons'}>
            <h1>
              {t('mercato.absoluteTitle')} <br />
              <small>Joueurs & structures</small>
            </h1>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                !authRepository.currentUser
                  ? authRepository.login(
                      window.location.origin +
                        routesConfig.parametersDetails.profilAppearence.to(),
                    )
                  : navigate(routesConfig.parametersDetails.profilAppearence.path);
              }}
            >
              Personnaliser mon profil
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                if (authRepository?.currentUser) {
                  const event = new CustomEvent(EVENT_OPEN_CREATE_STRUCTURE);
                  window.document.dispatchEvent(event);
                } else {
                  authRepository.login(
                    window.location.origin +
                      window.location.pathname +
                      '?createStructure=true',
                  );
                }
              }}
            >
              Créer une structure
            </Button>
          </div>
        </div>
        <div className={'-sliderContainer'}>
          <div className={'-explanationBlocs'}>
            <Slider
              speed={500}
              slidesToScroll={1}
              rows={1}
              slidesToShow={1}
              className={'slider'}
              arrows
              dots
              nextArrow={<ArrowRight />}
              prevArrow={<ArrowLeft />}
              infinite
            >
              <div className={'-titleBloc'}>
                <div className={'explanation-content'}>
                  <h2>Trouvez vos futures mates !</h2>
                  <p>
                    Utilisez le mercato pour agrandir votre réseau de joueurs et de
                    coéquipiers portentiels pour vous inscrire aux événements
                  </p>
                </div>
              </div>
              <div className={'-titleBloc'}>
                <div className={'explanation-content'}>
                  <h2>Structures e-sport amateurs et professionnels</h2>
                  <p>
                    Créez ou rejoignez une structure e-sports niveau amateur ou
                    professionnel.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <Section className={'mercato__content'}>
        <LayoutPageListFitlered
          listContent={
            <div className={'-content'} ref={refToSearch}>
              <UnpyTabs background={'mercato'} tabsConf={tabsConf} fonced center />
            </div>
          }
        />
      </Section>
    </Grid>
  );
};
