import { RolesEnum } from 'domain/security/RolesEnum';
import { User } from 'domain/user/User';
import { insertIf } from 'helper/array.helper';
import { ReactElement } from 'react';
import { Path } from 'react-router-dom';

import { routesConfig } from '../../Configs/Routes.config';

export type ConfParametersLink = {
  label: string;
  link: string | ((...args: any) => string);
  path: string | Path;
  icon?: ReactElement | string;
  params?: string[];
};

export type ConfParameterItem = {
  title: string;
  path: string;
  subMenu: ConfParametersLink[];
};

export type ListConfParametersMenu = {
  parametersItems: ConfParameterItem[];
  defaultTabUrl: string;
};

export const subMenuParametersProfilUser: ConfParametersLink[] = [
  {
    label: 'Utilisateur',
    link: routesConfig.parametersDetails.profilUtilisateur.to(),
    path: routesConfig.parametersDetails.profilUtilisateur.path,
  },
  {
    label: 'Apparence profil',
    link: routesConfig.parametersDetails.profilAppearence.to(),
    path: routesConfig.parametersDetails.profilAppearence.path,
  },
  {
    label: 'Jeux',
    link: routesConfig.parametersDetails.interests.to(),
    path: routesConfig.parametersDetails.interests.path,
  },
  {
    label: 'Réseaux et ping',
    link: routesConfig.parametersDetails.profilNetwork.to(),
    path: routesConfig.parametersDetails.profilNetwork.path,
  },
];

export const subMenuParametersCompte: ConfParametersLink[] = [
  {
    label: 'Données personnelles',
    link: routesConfig.parametersDetails.accountPersonnalData.to(),
    path: routesConfig.parametersDetails.accountPersonnalData.path,
  },
  {
    label: 'Sécurité',
    link: routesConfig.parametersDetails.account.to(),
    path: routesConfig.parametersDetails.account.path,
  },
];

export const subMenuParametersWallet: ConfParametersLink[] = [
  {
    label: 'Paiement & abonnement',
    link: routesConfig.parametersDetails.accountTransaction.to(),
    path: routesConfig.parametersDetails.accountTransaction.path,
  },
];

export const subMenuParametersAdmin: ConfParametersLink[] = [
  {
    label: 'Events',
    link: routesConfig.parametersDetails.eventsAdmin.to(),
    path: routesConfig.parametersDetails.eventsAdmin.path,
  },
];

export const menusParametersUser: (user?: User) => ListConfParametersMenu = (user) => ({
  defaultTabUrl: routesConfig.parametersDetails.profilUtilisateur.path,
  parametersItems: [
    {
      title: 'Profil',
      path: '/profil',
      subMenu: subMenuParametersProfilUser,
    },
    {
      title: 'Compte',
      path: '/account',
      subMenu: subMenuParametersCompte,
    },
    {
      title: 'Portefeuille',
      path: '/wallet',
      subMenu: subMenuParametersWallet,
    },
    ...insertIf(user?.roles?.includes(RolesEnum.admin), {
      title: 'Espace admin',
      path: '/admin-area',
      subMenu: subMenuParametersAdmin,
    }),
  ],
});
