import './ModalCreate.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { routesConfig } from '../Configs/Routes.config';
import { useTranslate } from '../hooks/useTranslate';
import { StructureCreateForm } from '../Structure/StructureCreateForm';

interface ModalCreateStructureProps {
  handleClose: () => void;
}

export const ModalCreateStructure = ({ handleClose }: ModalCreateStructureProps) => {
  const t = useTranslate();
  const navigate = useNavigate();

  return (
    <div className={'modal-create modal-create__structure'}>
      <div className={'-form'}>
        <StructureCreateForm
          handleClose={handleClose}
          onCreated={(id: number) => {
            navigate(routesConfig.parametersStructure.to(id));
          }}
        />
      </div>
    </div>
  );
};
