import {
  setAddFriendModalOpen,
  setInviteEventModalOpen,
  setInviteStructureModalOpen,
  setModalJoinStructureOpen,
  setSendMessagePlayerModalOpen,
  setSendMessageStructureModalOpen,
} from 'domain/modal/store/ModalSlice';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export const useRedirectUrlForAction = (
  action: OPEN_MODAL_ACTION,
  actionParam?: string,
  actionParamName?: string,
) => {
  const currentUrl = window?.location?.href;
  if (!currentUrl) {
    return;
  }
  const url = new URL(currentUrl);
  url.searchParams.set('modalAction', action);
  if (actionParam) {
    url.searchParams.set('actionParam', actionParam);
  }
  if (actionParamName) {
    url.searchParams.set('name', actionParamName);
  }

  return url;
};

export type OPEN_MODAL_ACTION =
  | 'addFriend'
  | 'joinStructure'
  | 'sendMessagePlayer'
  | 'inviteEvent'
  | 'sendMessageStructure'
  | 'modalLeaveStructure'
  | 'modalConfirm'
  | 'inviteStructure';

export const useOpenModalActionFromLogin = () => {
  const [searchParams, setUrlSearchParams] = useSearchParams();

  const openModal: OPEN_MODAL_ACTION | undefined = searchParams.get(
    'modalAction',
  ) as OPEN_MODAL_ACTION;
  const { authRepository } = useContextDependency();
  const actionParam = searchParams.get('actionParam');
  const actionParamName = searchParams.get('name');
  const dispatch = useDispatch();
  useEffect(() => {
    if (openModal) {
      switch (openModal) {
        case 'addFriend':
          dispatch(
            setAddFriendModalOpen({
              //@ts-ignore
              props: { userId: actionParam, name: actionParamName },
            }),
          );
          break;
        case 'joinStructure':
          dispatch(
            setModalJoinStructureOpen({
              props: {
                //@ts-ignore
                structureProfilId: actionParam,
                //@ts-ignore
                name: actionParamName,
              },
            }),
          );
          break;
        case 'sendMessagePlayer':
          dispatch(
            setSendMessagePlayerModalOpen({
              //@ts-ignore
              props: { name: actionParamName, userId: actionParam },
            }),
          );
          break;
        case 'inviteEvent':
          dispatch(
            setInviteEventModalOpen({
              //@ts-ignore
              props: { name: actionParamName, userId: actionParam },
            }),
          );
          break;
        case 'sendMessageStructure':
          dispatch(
            setSendMessageStructureModalOpen({
              //@ts-ignore
              props: { name: actionParamName, userId: actionParam },
            }),
          );
          break;
        case 'modalLeaveStructure':
          // dispatch(openModalLeaveStructure());
          break;
        case 'modalConfirm':
          // dispatch(openModalConfirm());
          break;
        case 'inviteStructure':
          dispatch(
            setInviteStructureModalOpen({
              //@ts-ignore
              props: { name: actionParamName, userId: actionParam },
            }),
          );
          break;
        default:
          break;
      }
      setUrlSearchParams(undefined);
    }
  }, []);
};
