import './FilterTalkLang.scss';

import { Country } from 'domain/country/Country';
import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectTag } from 'primary/Components/Input/InputSelectTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import React from 'react';

import { useTranslate } from '../../hooks/useTranslate';

type FilterCountryProps = {
  onSelectCountry: (values?: string) => void;
  selectedCountry?: string;
};

const _changeHandlerCountry =
  (setValue: (val: string) => void) => (event: ChangeHandlerTypeMeta) => {
    setValue(event.target.value as string);
  };

export const FilterCountry = ({
  onSelectCountry,
  selectedCountry,
}: FilterCountryProps) => {
  const t = useTranslate();
  const { countryRepository } = useContextDependency();
  const [countries] = useFetch(() => countryRepository.getAll(), undefined);

  return (
    <div>
      <InputSelectTag
        withAutoCompleteSearch
        key={countries?.length}
        placeholder={t('mercato.filters.placeholders.country')}
        options={insertEmptyOpt(
          countries?.map((country: Country) => ({
            label: country.libelle,
            value: country.code,
          })) || [],
        )}
        onChange={_changeHandlerCountry(onSelectCountry)}
        value={selectedCountry}
        menuPortalTarget={document.body}
      />
    </div>
  );
};
