import './ModalConfirm.scss';

import { setModalConfirmClose } from 'domain/modal/store/ModalSlice';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { useTranslate } from '../hooks/useTranslate';

type ModalConfirmSliweWrapperProps = {
  title: string;
  description?: string | ReactNode;
  labelAccept?: string;
  labelDecline?: string;
  action: () => void;
  isOpen: boolean;
};

const ModalConfirmSliceWrapper = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const stateModal = useSelector((state: RootState) => state.modal.modalConfirm);
  const handleClose = () => dispatch(setModalConfirmClose());
  const { title, description, labelDecline, labelAccept, action } =
    stateModal.props as ModalConfirmSliweWrapperProps;
  return (
    <ModalConfirm
      handleClose={handleClose}
      title={title}
      action={action}
      isOpen={stateModal.isOpen}
      labelDecline={labelDecline}
      labelAccept={labelAccept}
      description={description}
    />
  );
};

export default ModalConfirmSliceWrapper;
