import { setAddFriendModalClose } from 'domain/modal/store/ModalSlice';
import {
  selectConnectedUserFriend,
  setRelation,
} from 'domain/relation/store/RelationSlice';
import { AddFriendForm } from 'primary/Components/Profils/User/ActionButton/Forms/AddFriendForm';
import { WrapperModalAction } from 'primary/Components/Profils/User/ActionButton/Modals/WrapperModalAction';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
interface ModalAddFriendProps {
  isOpen: boolean;
  handleClose: () => void;
  userId?: number;
  name: string;
  alreadyFriend?: boolean;
}

export const ModalAddFriend = () => {
  const t = useTranslate();
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);
  const { relationRepository, authRepository } = useContextDependency();
  const stateModal = useSelector((state: RootState) => state.modal.addFriend);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setAddFriendModalClose());
  const { userId, name } = stateModal.props as ModalAddFriendProps;
  const friend = useSelector(selectConnectedUserFriend);
  const alreadyFriend = friend?.find(
    (b) =>
      b.invited.id?.toString() === userId?.toString() ||
      b.owner.id?.toString() === userId?.toString(),
  );
  const isSameUserConencted =
    authRepository?.currentUser?.id?.toString() === userId?.toString();
  const onSubmitted = () => {
    handleClose();
    relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
      dispatch(setRelation(relations));
    });
  };

  useEffect(() => {
    if (isSameUserConencted) {
      handleClose();
    }
  }, [isSameUserConencted]);

  if (!userId) return null;
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={stateModal.isOpen}
      header={{
        title: t('modals.addFriend.title', { name: name }),
        withButtonClose: true,
      }}
      content={
        <WrapperModalAction closeModal={handleClose} loading={friend === undefined}>
          <>
            {alreadyFriend && (
              <div>{t('modals.addFriend.alreadyFriend', { name: name })}</div>
            )}
            {!alreadyFriend && (
              <AddFriendForm
                onSubmitted={onSubmitted}
                userId={userId?.toString()}
                onCancel={() => handleClose()}
              />
            )}
          </>
        </WrapperModalAction>
      }
    />
  );
};
