import './InputSelectTag.scss';

import { ArrowDropDown } from '@mui/icons-material';
import {
  Chip,
  ClickAwayListener,
  Fade,
  FormControl,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { debounce, isArray } from 'lodash';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import React, { forwardRef, Ref, useEffect, useRef } from 'react';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { FixedSizeList as List } from 'react-window';

import { InputProps, Option, ValueType } from './InputWrapper';

type InputSelectMuiProps<T extends ValueType = string, M = object> = {
  withAutoCompleteSearch?: boolean;
  required?: boolean;
  options?: Option<string, M>[];
  isSearchable?: boolean;
  onInputChange?: (newValue: string) => void;
  filterOption?: (opt: FilterOptionOption<Option<string, M>>, input: string) => boolean;
  menuPortalTarget?: HTMLElement | null;
  multiple?: boolean;
  isOptionDisabled?: (option: Option<string, M>) => boolean;
  isLoading?: boolean;
  onLoadingMessage?: string;
  noContentMessage?: string;
  variant?: 'standard' | 'outlined' | 'filled' | 'tag';
} & InputProps<T>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  onClick: (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  },
};

export const InputSelectTag = forwardRef(function InputSelectTagForwarded(
  {
    value,
    onChange,
    options,
    multiple,
    isLoading,
    onLoadingMessage,
    noContentMessage,
    variant,
    disabled,
    placeholder,
    withAutoCompleteSearch,
  }: InputSelectMuiProps,
  ref: Ref<HTMLElement>,
) {
  const valueDefault = isLoading || options?.length === 0 ? 'search-disabled' : '';
  const anchorElTagSelect = useRef<HTMLDivElement>(null);
  const isDisplayPlaceholder =
    ((value as string)?.includes('undefined') ||
      (isArray(value) && !value?.length) ||
      !value) &&
    !!placeholder;

  const [searchTextValueInOpt, setSearchTextValueInOpt] = React.useState<
    string | undefined
  >(undefined);

  const [optionsFiltered, setOptionsFiltered] = React.useState<
    Option<string, object>[] | undefined
  >(options);

  useEffect(() => {
    if (!withAutoCompleteSearch) return;
    if (searchTextValueInOpt) {
      setOptionsFiltered(
        options?.filter((opt) =>
          (opt?.label as string)
            ?.toLowerCase()
            .includes(searchTextValueInOpt.toLowerCase()),
        ) || [],
      );
    } else {
      setOptionsFiltered(options || []);
    }
  }, [searchTextValueInOpt]);

  const [isOpenTagSelect, , openModalTagSelect, closeModalTagSelect] = useBooleanToggle();
  const debouncedOnChange = debounce(
    (value: string) => {
      setSearchTextValueInOpt(value);
    },
    500,
    { leading: true },
  );

  const Row = ({ index, style }: { index: number; style: any }) => (
    <MenuItem
      style={{ minHeight: '30px', ...style }}
      key={optionsFiltered?.[index]?.value}
      onClick={() => {
        if (!multiple) {
          closeModalTagSelect();
        }
        onChange?.({ target: { value: optionsFiltered?.[index]?.value } });
      }}
    >
      {optionsFiltered?.[index]?.label}
    </MenuItem>
  );

  return (
    <FormControl sx={{ width: '100%' }}>
      <Chip
        label={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: !isDisplayPlaceholder ? '#ff7800' : 'white',
            }}
          >
            {isDisplayPlaceholder ? (
              <span className={'-selectTagPlaceholder'}>{placeholder}</span>
            ) : (
              <span className={'-selectTagValue'}>
                {isArray(value)
                  ? value?.map((v) => v).join(', ')
                  : options?.find((p) => p.value === value)?.label}
              </span>
            )}
            <ArrowDropDown />
          </div>
        }
        ref={anchorElTagSelect}
        onClick={() => openModalTagSelect()}
      />
      <Popper
        open={isOpenTagSelect}
        anchorEl={anchorElTagSelect.current}
        placement={'bottom-start'}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener onClickAway={closeModalTagSelect}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'inputSelectTag-menu__paper '}>
                  {withAutoCompleteSearch && (
                    <>
                      <TextField
                        variant={'standard'}
                        style={{ width: '100%', marginBottom: '10px', padding: '0 4px' }}
                        disabled={disabled}
                        placeholder={'Rechercher un pays'}
                        onChange={(event) => {
                          debouncedOnChange(event.target.value);
                          return event;
                        }}
                      />
                      <List
                        className="List -listSelectTag"
                        height={200}
                        itemCount={optionsFiltered?.length ?? 1}
                        itemSize={35}
                        width={300}
                      >
                        {Row}
                      </List>
                    </>
                  )}
                  {!withAutoCompleteSearch && (
                    <>
                      {options?.map((opt) => (
                        <MenuItem
                          style={{ minHeight: '30px' }}
                          key={opt.value}
                          onClick={() => {
                            if (!multiple) {
                              closeModalTagSelect();
                            }
                            onChange?.({ target: { value: opt?.value } });
                          }}
                        >
                          {opt.label}
                        </MenuItem>
                      ))}
                    </>
                  )}
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </FormControl>
  );
});
