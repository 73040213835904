import './ActionButton.scss';

import { Send } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  setSendMessagePlayerModalOpen,
  setSendMessageStructureModalOpen,
} from 'domain/modal/store/ModalSlice';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import {
  isPrivacyRestricted,
  isStructureProfil,
  isUserProfil,
} from 'domain/profil/Profil.func';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { UserProfil } from 'domain/profil/UserProfil';
import { selectConnectedUserBlocked } from 'domain/relation/store/RelationSlice';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRedirectUrlForAction } from 'primary/hooks/useOpenModalActionFromLogin';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ActionButtonSendMessageProps {
  profil: UserProfil | StructureProfil;
  withIcon?: boolean;
}

export const ActionButtonSendMessage = ({
  profil,
  withIcon,
}: ActionButtonSendMessageProps) => {
  const [isOpen, toggleOpen, openModal, closeModal] = useBooleanToggle();
  const { authRepository } = useContextDependency();

  const t = useTranslate();
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.id === profil.id);
  const isRestricted =
    !!alreadyBlocked || isPrivacyRestricted(profil, PRIVACY_CODE.SEND_MESSAGE);
  const isUserProfilCalculated = isUserProfil(profil);
  const dispatch = useDispatch();
  const urlRedirect = useRedirectUrlForAction(
    isUserProfilCalculated ? 'sendMessagePlayer' : 'sendMessageStructure',
    isUserProfilCalculated ? profil.userId?.toString() : profil?.id?.toString(),
    isUserProfilCalculated ? profil.idPlayer : profil.name,
  );

  const handleClick = () => {
    if (isUserProfil(profil)) {
      dispatch(
        setSendMessagePlayerModalOpen({
          props: { name: profil.idPlayer, userId: profil.userId },
        }),
      );
    } else if (isStructureProfil(profil)) {
      dispatch(
        setSendMessageStructureModalOpen({
          //@ts-ignore
          props: { name: profil.name, userId: profil.id },
        }),
      );
    }
  };
  const openModalAddFriend = () => {
    if (authRepository?.currentUser) {
      handleClick();
    } else {
      if (urlRedirect) {
        authRepository?.login(urlRedirect.toString());
      }
    }
  };
  return (
    <div>
      <Button
        color={'inherit'}
        variant={'text'}
        className={'-actionButton'}
        onClick={openModalAddFriend}
        disabled={isRestricted}
      >
        {withIcon && <Send />}
        {t('profil.userProfilAction.sendMessage')}
      </Button>
    </div>
  );
};
