import { PodiumProfil } from 'domain/classement/PodiumProfil';
import {
  RestClassementParticipant,
  toClassementParticipant,
} from 'secondary/classement/RestClassementParticipant';

export interface RestPodiumProfil {
  firstPlace: RestClassementParticipant[];
  secondPlace: RestClassementParticipant[];
  thirdPlace: RestClassementParticipant[];
  noPodium: RestClassementParticipant[];
}

export function toPodiumProfil(
  restClassementParticipant: RestPodiumProfil,
): PodiumProfil {
  const { firstPlace, thirdPlace, secondPlace, noPodium } = restClassementParticipant;
  return new PodiumProfil(
    firstPlace.map(toClassementParticipant),
    secondPlace.map(toClassementParticipant),
    thirdPlace.map(toClassementParticipant),
    noPodium.map(toClassementParticipant),
  );
}
