import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useMustConnected } from 'primary/hooks/useMustConnected';
import { useTranslate } from 'primary/hooks/useTranslate';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  setOneConnectedUserStructure,
  setOneConnectedUserStructureProfil,
} from '../../../domain/user/store/UserSlice';
import { RootState } from '../../../store';
import { useContextDependency } from '../../hooks/useContextDependency';

export const useStructureParameters = () => {
  const { idStructure } = useParams<{ idStructure: string }>();
  const structureOfProfil = useSelector(
    (state: RootState) => state.user.structuresOfUser,
  );
  const { user, isAccountLoaded } = useMustConnected();
  const [isInitialStructureFetching, setIsInitialStructureFetchibg] = useState(
    !structureOfProfil,
  );
  const dispatch = useDispatch();
  const { structureProfilRepository, structureRepository } = useContextDependency();
  const t = useTranslate();

  useEffect(() => {
    if (!structureOfProfil) {
      setIsInitialStructureFetchibg(true);
      structureRepository
        .getStructureById(idStructure as unknown as number)
        .then((value) => dispatch(setOneConnectedUserStructure(value)))
        .finally(() => setIsInitialStructureFetchibg(false));
    }
  }, [structureOfProfil, idStructure, isAccountLoaded]);

  const onSubmitted = () => {
    sendEventToastMessage(t('general.edit.success'), 'success');
    structureRepository
      .getStructureById(idStructure as unknown as number)
      .then((value) => {
        dispatch(setOneConnectedUserStructure(value));
        value.structureProfil &&
          dispatch(setOneConnectedUserStructureProfil(value.structureProfil));
      });
  };

  const refrehStructure = (idStructure?: number) => {
    structureRepository
      .getStructureById(idStructure as unknown as number)
      .then((value) => dispatch(setOneConnectedUserStructure(value)));
  };

  const structure = useMemo(
    () =>
      structureOfProfil?.find((value) => value.id === parseInt(idStructure as string)),
    [structureOfProfil, idStructure],
  );
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (isAccountLoaded && user && structure) {
  //     console.log('members', structure.members);
  //     console.log(
  //       'FINDED MEMBERS',
  //       structure.members.find((m) => m.role.code === RolesStructureEnum.PRES),
  //     );
  //     if (
  //       structure.members.find((m) => m.role.code === RolesStructureEnum.PRES)?.user.id !=
  //       user.id
  //     ) {
  //       navigate(routesConfig.home.to());
  //     }
  //   }
  // }, [isAccountLoaded, user, structure]);

  return {
    onSubmitted,
    refrehStructure,
    structure,
    structureProfilRepository,
    user,
    isAccountLoaded,
    isInitialStructureFetching,
  };
};
