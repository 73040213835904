import { Block } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { setModalConfirmClose, setModalConfirmOpen } from 'domain/modal/store/ModalSlice';
import { UserProfil } from 'domain/profil/UserProfil';
import { setRelation } from 'domain/relation/store/RelationSlice';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch } from 'react-redux';

interface ActionButtonBlockProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const ActionButtonBlock = ({ profil, withIcon }: ActionButtonBlockProps) => {
  const t = useTranslate();
  const { relationRepository, authRepository } = useContextDependency();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setModalConfirmClose());

  const handleBlock = () => {
    if (!profil.userId) return;
    setIsLoading(true);
    relationRepository
      .blockUser(profil.userId)
      .then(() => {
        sendEventToastMessage(
          t(`relations.block.success`, { name: profil.idPlayer }),
          'success',
        );
        relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
          dispatch(setRelation(relations));
        });
      })
      .finally(() => {
        setIsLoading(false);
        handleClose();
      });
  };

  const openModal = () => {
    if (authRepository?.currentUser) {
      dispatch(
        setModalConfirmOpen({
          props: {
            title: t('relations.block.title', { name: profil.idPlayer }),
            labelDecline: t(`relations.block.cancelBtnText`),
            labelAccept: t(`relations.block.confirmBtnText`),
            action: handleBlock,
            description: t(`relations.block.notice`),
          },
        }),
      );
    } else {
      authRepository?.login(window.location.href);
    }
  };

  return (
    <>
      <LoadingButton
        loading={isLoading}
        color={'inherit'}
        variant={'text'}
        className={'-actionButton'}
        onClick={stopPropagationBeforeAction(openModal)}
      >
        {withIcon && <Block color={'error'} className={'-error'} />}
        {t(`profil.userProfilAction.block`)} <br />
      </LoadingButton>
    </>
  );
};
