import './LoggedInUserAvatarMenu.scss';

import {
  Apartment,
  Games,
  Groups,
  Logout,
  Person,
  Settings,
  SportsEsports,
} from '@mui/icons-material';
import {
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
} from '@mui/material';
import { MessagePreview } from 'primary/Components/User/MessagePreview';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { User } from '../../../domain/user/User';
import { routesConfig } from '../../Configs/Routes.config';
import { useBooleanToggle } from '../../hooks/useBooleanToggle';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate } from '../../hooks/useTranslate';
import { ModalAddContent, ModalCreateTypes } from './ModalAddContent';
import { NotificationPreview } from './NotificationPreview';
import { OwnProfilPhotoOverview } from './OwnProfilPhotoOverview';

interface CreateMenuProps {
  handleOpenModalCreate: (type: ModalCreateTypes) => void;
}

export const EVENT_OPEN_CREATE_STRUCTURE = 'EVENT_OPEN_CREATE_STRUCTURE';
export const EVENT_OPEN_CREATE_UNPY_EVENT = 'EVENT_OPEN_CREATE_UNPY_EVENT';

const CreateMenu: FC<CreateMenuProps> = ({ handleOpenModalCreate }) => {
  const t = useTranslate();
  const anchorElCreate = useRef<HTMLButtonElement>(null);
  const [modalCreateOpen, setModalCreateOpen] = useState(false);
  const handleClickCreate = () => {
    setModalCreateOpen(true);
  };
  const handleClose = () => {
    setModalCreateOpen(false);
  };

  const navigate = useNavigate();

  const onClickStructure = () => {
    handleClose();
    return handleOpenModalCreate('STRUCTURE');
  };

  return (
    <>
      <IconButton
        className={'create-menu'}
        onClick={handleClickCreate}
        ref={anchorElCreate}
        aria-controls={modalCreateOpen ? 'create-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={modalCreateOpen ? 'true' : undefined}
      >
        +
      </IconButton>
      <Popper
        open={modalCreateOpen}
        anchorEl={anchorElCreate.current}
        placement={'bottom-start'}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'create-menu__paper'}>
                  <>
                    <span className={'-title'}>Création</span>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleOpenModalCreate('EVENT');
                      }}
                    >
                      {t('event.createEvent')}
                    </MenuItem>
                    <MenuItem onClick={onClickStructure}>
                      {t('structure.createStructure')}
                    </MenuItem>
                  </>
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </>
  );
};

interface LoggedInUserAvatarMenuProps {
  user: User;
  socketReady: boolean;
}

export const LoggedInUserAvatarMenu = ({
  user,
  socketReady,
}: LoggedInUserAvatarMenuProps) => {
  const anchorElAccountMenu = useRef<HTMLButtonElement>(null);
  const [isOpenAccountMenu, , openModalAccountMenu, closeModalAccountMenu] =
    useBooleanToggle();
  const [searchParams] = useSearchParams();

  const createStructure = searchParams.get('createStructure');
  const createEvent = searchParams.get('createEvent');

  const [openModalType, setOpenModalType] = useState<ModalCreateTypes | undefined>();
  const [isOpen, , openModal, closeModal] = useBooleanToggle();
  const t = useTranslate();
  const handleClickOpenAccountMenu = () => {
    openModalAccountMenu();
  };

  const handleClickCloseAccountMenu = () => {
    closeModalAccountMenu();
  };
  const alreadyOpenedCreateStructrureAfterLogin = useRef<boolean>(false);
  const alreadyOpenedCreateEventAfterLogin = useRef<boolean>(false);
  const handleOpenModalCreate = (type: ModalCreateTypes) => {
    setOpenModalType(type);
    openModal();
  };

  const navigate = useNavigate();

  const { authRepository } = useContextDependency();

  useEffect(() => {
    const eventListenerOpenStructure = (() => {
      handleOpenModalCreate('STRUCTURE');
      // eslint-disable-next-line no-undef
    }) as EventListener;

    const eventListenerOpenEvent = (() => {
      handleOpenModalCreate('EVENT');
      // eslint-disable-next-line no-undef
    }) as EventListener;

    document.addEventListener(EVENT_OPEN_CREATE_STRUCTURE, eventListenerOpenStructure);
    document.addEventListener(EVENT_OPEN_CREATE_UNPY_EVENT, eventListenerOpenEvent);
    return () => {
      document.removeEventListener(
        EVENT_OPEN_CREATE_STRUCTURE,
        eventListenerOpenStructure,
      );
      document.removeEventListener(EVENT_OPEN_CREATE_UNPY_EVENT, eventListenerOpenEvent);
    };
  }, []);

  useEffect(() => {
    if (
      !alreadyOpenedCreateStructrureAfterLogin.current &&
      !!authRepository?.currentUser &&
      createStructure === 'true'
    ) {
      handleOpenModalCreate('STRUCTURE');
      alreadyOpenedCreateStructrureAfterLogin.current = true;
    }
  }, [
    authRepository?.currentUser,
    alreadyOpenedCreateStructrureAfterLogin.current,
    createStructure,
  ]);

  useEffect(() => {
    if (
      !alreadyOpenedCreateEventAfterLogin.current &&
      !!authRepository?.currentUser &&
      createEvent === 'true'
    ) {
      handleOpenModalCreate('EVENT');
      alreadyOpenedCreateEventAfterLogin.current = true;
    }
  }, [
    authRepository?.currentUser,
    alreadyOpenedCreateEventAfterLogin.current,
    createEvent,
  ]);

  return (
    <>
      <NotificationPreview userId={user.id} />
      <MessagePreview userId={user.id} />
      <CreateMenu handleOpenModalCreate={handleOpenModalCreate} />
      {openModalType && (
        <ModalAddContent
          isOpen={isOpen}
          handleClose={closeModal}
          modalCreateType={openModalType}
        />
      )}
      <IconButton
        onClick={handleClickOpenAccountMenu}
        aria-controls={isOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        ref={anchorElAccountMenu}
      >
        <OwnProfilPhotoOverview className={'avatar-user'} />
      </IconButton>
      <Popper
        open={isOpenAccountMenu}
        anchorEl={anchorElAccountMenu.current}
        placement={'bottom-start'}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener onClickAway={handleClickCloseAccountMenu}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'account-menu__paper'}>
                  <span className={'-idPlayer'}>{user.userProfil.idPlayer}</span>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      handleClickCloseAccountMenu();
                      navigate(routesConfig.parametersUser.to());
                    }}
                  >
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.parameters')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClickCloseAccountMenu();
                      navigate(routesConfig.mercatoUserProfil.to(user.userProfil.id));
                    }}
                  >
                    <ListItemIcon>
                      <Person fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.myProfil')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClickCloseAccountMenu();
                      navigate(routesConfig.relationPage.to());
                    }}
                  >
                    <ListItemIcon>
                      <Groups fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.relations')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClickCloseAccountMenu();
                      navigate(routesConfig.myStructures.to());
                    }}
                  >
                    <ListItemIcon>
                      <Apartment fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.myStructures')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClickCloseAccountMenu();
                      navigate(routesConfig.myEvents.to());
                    }}
                  >
                    <ListItemIcon>
                      <SportsEsports fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.myEvents')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClickCloseAccountMenu();
                      navigate(routesConfig.activities.to());
                    }}
                  >
                    <ListItemIcon>
                      <Games fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.activities')}
                    </ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className={'link'}
                    onClick={() => authRepository.logout(true)}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" color={'error'} />
                    </ListItemIcon>
                    <ListItemText>{t('topbar.menuConnected.label.logout')}</ListItemText>
                  </MenuItem>
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </>
  );
};
