import './EventListPage.scss';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { toUpper } from 'lodash';
import { EVENT_OPEN_CREATE_UNPY_EVENT } from 'primary/Components/User/LoggedInUserAvatarMenu';
import { EventsSlider } from 'primary/events/components/EventsSlider';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useSelectionGameListPage } from 'primary/Mercato/Mercato.helper';
import React, { FC, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import { LayoutPageListFitlered } from '../Components/Layout/Pages/LayoutPageListFiltered';
import { Section } from '../Components/Section/Section';
import { useTranslate } from '../hooks/useTranslate';
import { EventList } from './list/EventList';

const ArrowLeft = (props: any) => {
  return (
    <KeyboardArrowLeft
      {...props}
      className={classNames(props.className, '-arrow', '-left')}
    />
  );
};

const ArrowRight = (props: any) => {
  return (
    <KeyboardArrowRight
      {...props}
      className={classNames(props.className, '-arrow', '-right')}
    />
  );
};

export const SliderEventsCashprize = () => {
  const { eventRepository } = useContextDependency();
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<UnpyEventView, Partial<UnpyEventFilters>>({
    keyStoreFilters: 'event-home-open',
    staticFilters: {
      status: EventStatusEnum.PUBLISH,
      endSubscribeDate: formatDate(new Date(), 'back(datetime) UTC'),
      placeAvailable: 'true',
    },
    filters: {},
    callApi: (filters, page, nbPerPage) => eventRepository.searchHome('CASHPRIZE', page),
  });

  return (
    <div
      style={{
        width: '100%',
        margin: '16px 32px',
      }}
    >
      <div className={'sliderEventTitle'}>
        <h2
          style={{
            margin: '8px 0',
          }}
        >
          Remporte un cashprize !
          <br />
          <span style={{ fontSize: '80%' }}>
            Participe à ces tournois pour avoir une chance de remporter un prix.
          </span>
        </h2>
      </div>
      <EventsSlider
        type={'CASHPRIZE'}
        noContentMessage={'Aucun évenement avec cashprize'}
        events={fetchedResource?.content}
      />
    </div>
  );
};

export const SliderEventsFreeRegister = () => {
  const { eventRepository } = useContextDependency();
  const { fetchedResource } = useFetchWithFilterPage<
    UnpyEventView,
    Partial<UnpyEventFilters>
  >({
    keyStoreFilters: 'event-home-open',
    staticFilters: {
      status: EventStatusEnum.PUBLISH,
      placeAvailable: 'true',
    },
    filters: {},
    callApi: (filters, page, nbPerPage) =>
      eventRepository.searchHome('FREE_REGISTER', page, undefined),
  });

  return (
    <div
      style={{
        width: '100%',
        margin: '16px 32px',
      }}
    >
      <div className={'sliderEventTitle'}>
        <h2>
          Inscriptions gratuites ! <br />
          <span style={{ fontSize: '80%' }}>
            Aucun frais d'inscription sur ces événements.
          </span>
        </h2>
      </div>
      <EventsSlider
        type={'FREE_REGISTER'}
        noContentMessage={'Aucun événement avec cashprize'}
        events={fetchedResource?.content}
      />
    </div>
  );
};

export const EventListPage: FC = () => {
  const t = useTranslate();
  const { selectedGamesCode, handleSelection } = useSelectionGameListPage('event');
  const [searchParams, setUrlSearchParams] = useSearchParams();
  const gameToFilter = searchParams?.get('game');
  const refGameSetted = useRef(false);
  const refPortal = useRef<HTMLDivElement>();
  const { authRepository } = useContextDependency();
  useEffect(() => {
    if (gameToFilter && !selectedGamesCode.find((game) => game === gameToFilter)) {
      handleSelection(gameToFilter, 'ADD');
      refGameSetted.current = true;
      setUrlSearchParams({});
    }
  }, [gameToFilter, selectedGamesCode]);

  const ref = useRef<HTMLDivElement | null>(null);
  const goToRef = () =>
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  return (
    <div className={'event-list-page'}>
      <div className={'-headerEventsPage'}>
        <div className={'-titleContainer'}>
          <div className={'-titleWithButtons'}>
            <h1>{toUpper(t('event.eventsListPage.titleEvents'))}</h1>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                if (authRepository?.currentUser) {
                  const event = new CustomEvent(EVENT_OPEN_CREATE_UNPY_EVENT);
                  window.document.dispatchEvent(event);
                } else {
                  authRepository.login(
                    window.location.origin +
                      window.location.pathname +
                      '?createEvent=true',
                  );
                }
              }}
            >
              Créer un événement
            </Button>
          </div>
        </div>
        <div className={'-sliderContainer'}>
          <div className={'-explanationBlocs'}>
            <Slider
              speed={500}
              slidesToScroll={1}
              rows={1}
              slidesToShow={1}
              className={'slider'}
              arrows
              dots
              nextArrow={<ArrowRight />}
              prevArrow={<ArrowLeft />}
              infinite
            >
              <div className={'-titleBloc'}>
                <div className={'explanation-content'}>
                  <h2>Organisez vos propres événements !</h2>
                  <p>
                    Gérez entièrement vos événements, de la publication à la distribution
                    en passant par la gestion des inscriptions, des équipes et des scores
                    simplifiés par Unpy.
                  </p>
                </div>
              </div>
              <div className={'-titleBloc'}>
                <div className={'explanation-content'}>
                  <h2>Participez à des événements sur vos jeux favoris</h2>
                  <p>
                    Faites grandir votre réseau, progressez, et affichez vos résultats sur
                    votre profil pour avoir une chance de vous faire recruter !
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <Section className={'event-list-page__section'}>
        <LayoutPageListFitlered
          listContent={
            <div className={'event-list-page__listEvents'} ref={ref}>
              <EventList
                key={'finished'}
                title={t('event.eventsListPage.titleEventListFinished')}
                gamesSelected={selectedGamesCode}
                typeSearch={'PUBLIC'}
              />
            </div>
          }
        />
      </Section>
    </div>
  );
};
